<script>
export default {
  methods: {
    async initGmailSignin() {
      if (this.$google.isInit) {
        // Listen for sign-in state changes.
        this.$google.api.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);

        // Handle the initial sign-in state.
        this.$stash.gmail.signedIn = this.$google.api.auth2.getAuthInstance().isSignedIn.get();
        if (this.$stash.gmail.signedIn) {
          this.setGmailEmailAddress();
        }
        this.$stash.gmail.loading = false;
      } else {
        await this.timeout(500);
        await this.initGmailSignin();
      }
    },
    async updateSigninStatus(val) {
      this.$stash.gmail.signedIn = val;
      if (this.$stash.gmail.signedIn) {
        this.setGmailEmailAddress();
        this.$EventBus.$emit('updatedEmails');
      }
    },
    async signInWithGoogle() {
      await this.$google.api.auth2.getAuthInstance().signIn();
    },
    setGmailEmailAddress() {
      const vm = this;
      this.$google.api.client.request({
        path: 'gmail/v1/users/me/profile',
        method: 'GET',
      }).then((response) => {
        this.$stash.gmail.emailAddress = response.result.emailAddress;
      });
    },
    signOutGmail() {
      this.$google.api.auth2.getAuthInstance().signOut();
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
};
</script>
