const state = {
  sideBarActive: false
}

const mutations = {
  TOGGLE_SIDE_BAR (state, data) {
    state.sideBarActive = data
  }
}

const actions = {
  toggleSideBar ({ commit, state }) {
    commit('TOGGLE_SIDE_BAR', !state.sideBarActive)
  },
  closeSideBar ({ commit }) {
    commit('TOGGLE_SIDE_BAR', false)
  }
}

const getters = {
  sideBarActive (state) {
    return state.sideBarActive
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
