import axios from "axios";
import store from "@/store";

const api = axios;

// When the api is called by one of the models it will use the V2
// endpoint - otherwise it will use the normal endpoint
// Setup default base url
api.defaults.baseURL = process.env.VUE_APP_API_URL_V2;

// If the token is present attach it to all requests
api.interceptors.request.use(
  (config) => {
    const token = store.getters["auth/token"];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// Hit an un-authed status? Log user out & redirect
api.interceptors.response.use(
  (response) => {
    if (response.data["subscription-error"] == true) {
      return (window.location = "/subscription-expired");
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("auth/logout");
    }
    return Promise.reject(error);
  }
);

export default api;
