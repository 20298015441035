<template>
  <div
    :class="[
      'busy row text-center',
      !isVisible ? 'd-none' : '',
      isVisible || button ? 'fadeIn' : 'fadeOut',
      buttonMode ? 'insideButton' : 'layer',
      size
    ]"
  >
    <div class="loadingIcon">
      <i class="fas fa-spin fa-circle-notch mx-auto text-primary"></i>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["visible", "text", "button", "s", "m", "l"],
  data() {
    return {
      size: 'large',
      buttonMode: false
    }
  },
  computed: {
    isVisible() {
      return this.visible === true || (this.button === undefined && this.visible === undefined)
    },
  },
  mounted() {
    if (this.s !== undefined) {
      this.size = 'small'
    }
    if (this.l !== undefined) {
      this.size = 'large'
    }
    if (this.m !== undefined) {
      this.size = 'medium'
    }
    if (this.button !== undefined) {
      this.buttonMode = true
    }
  }
}
</script>

<style>
.busy.insideButton div {
  display: inline;
}
.busy.insideButton {
  background-color: transparent;
  display: inline;
  margin-left: 0;
  margin-right: 0.5rem;
}
.busy.layer.small i {
  font-size: 2vw !important;
}
.busy.layer.medium i {
  font-size: 3vw !important;
}
.busy.layer.large i {
  font-size: 5.0vw !important;
}
.busy.layer .loadingIcon {
  font-size: 18px;
}
.busy.layer {
  background-color: rgba(255,255,255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
}
.fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 200ms, opacity 200ms;
}
.fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 200ms;
}
</style>