<template>
  <div>
    <!--  -->
    <div class="add-anything-menu noprint" v-tooltip.left="'Add anything'">
      <div class="btn-group dropup">
        <button
          type="button"
          class="btn-primary btn-lg shadow"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          data-display="static"
        >
          <i class="far fa-plus mt-1" style="font-size: 1.75rem"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-left shadow">
          <a
            class="dropdown-item"
            href="#"
            data-toggle="modal"
            data-target="#addNewClient"
            @click="addClient = !addClient"
          >Add Client</a>
          <a class="dropdown-item" href="#">Add Community Post</a>
        </div>
      </div>
    </div>
    <!--  -->

    <add-client :user="user" v-if="addClient" @closed="addClient = !addClient" @added="clientAdded"></add-client>
    <!--  -->
    <!--  -->
  </div>
</template>

<script>
import AddClient from "@/views/components/clients/AddClient";

export default {
  props: ["user", "path"],
  data() {
    return {
      addClient: false,
    };
  },
  methods: {
    clientAdded() {
      this.$EventBus.$emit("clientAdded");
    }
  },
  mounted() {
    this.$EventBus.$on("showAddNewClient", e => {
      this.addClient = true;
      this.$nextTick(function() {
        $("#addNewClient").modal("show");
      });
    });
  },
  components: {
    AddClient,
  }
};
</script>

<style>
.add-anything-menu {
  position: fixed;
  right: 0.5rem;
  bottom: 0.5rem;
  z-index: 1050;
}
.add-anything-menu button {
  width: 65px;
  height: 65px;
  text-align: center;
  border-radius: 50%;
  outline: none !important;
}
.add-anything-menu .dropdown-menu {
  left: -10rem !important;
  bottom: 4.5rem !important;
}
</style>