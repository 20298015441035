<template>
  <div class="position-relative mb-0 mb-md-0" v-click-outside="close">
    <div class="input-group me-sm-2">
      <input
        class="form-control form-control-purple onboarding-tour-step-6"
        type="text"
        v-model="searchTerm"
        @input="search"
        placeholder="Search for clients, orders and more..."
        aria-label="Search for clients, orders and more..."
      />
      <div class="input-group-append form-control-purple">
        <div class="input-group-text">
          <div>
            <span class="cursor-pointer" @click="searchTerm = ''"
              ><i class="far fa-times"></i
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="position-absolute w-100 bg-white mt-2 shadow"
      v-if="results.length > 0 || featureResults.length > 0"
    >
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item cursor-pointer"
          v-for="r in results"
          :key="r.id"
          v-if="r.type === 'client'"
          @click="viewResult(r)"
        >
          <div class="row">
            <div class="col">{{ r.name }} / {{ r.email }}</div>
            <div class="col-auto text-right">
              <span class="rounded-pill bg-primary text-white py-1 px-2">
                <small>Client</small>
              </span>
            </div>
          </div>
        </li>

        <li
          class="list-group-item cursor-pointer"
          v-for="r in results"
          :key="r.id"
          v-if="r.type === 'order'"
          @click="viewOrderResult(r)"
        >
          <div class="row">
            <div class="col">
              {{ r.id }} / £{{ r.total }} - {{ r.client.name }}
            </div>
            <div class="col-auto text-right">
              <span class="rounded-pill bg-primary text-white py-1 px-2">
                <small>Order</small>
              </span>
            </div>
          </div>
        </li>

        <li
          class="list-group-item cursor-pointer"
          v-for="r in results"
          :key="r.id"
          v-if="r.type === 'faq'"
          @click="viewFaqResult(r)"
        >
          <div class="row">
            <div class="col">{{ r.title }}</div>
            <div class="col-auto text-right">
              <span class="rounded-pill bg-primary text-white py-1 px-2">
                <small>FAQ</small>
              </span>
            </div>
          </div>
        </li>

        <li
          class="list-group-item cursor-pointer"
          v-for="r in featureResults"
          :key="r.id"
          @click="viewFeatureResult(r)"
        >
          <div class="row">
            <div class="col">{{ r.title }}</div>
            <div class="col-auto text-right">
              <span class="rounded-pill bg-primary text-white px-2">
                <small>Feature</small>
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
export default {
  data() {
    return {
      searchTerm: "",
      results: [],
      featureResults: [],
    };
  },
  watch: {
    searchTerm: {
      handler(val) {
        if (val.length === 0) {
          this.search();
        }
      },
    },
  },
  methods: {
    viewResult(r) {
      this.results = [];
      this.featureResults = [];
      this.searchTerm = "";
      this.$stash.clientInView = r.id;
      this.$router.push("/clients/" + r.id);
    },
    viewFaqResult(r) {
      this.results = [];
      this.featureResults = [];
      this.searchTerm = "";
      window.open("https://support.swandoola.com/faqs/" + r.slug, "_blank");
    },
    viewOrderResult(r) {
      this.results = [];
      this.featureResults = [];
      this.searchTerm = "";
      this.$router.push("/orders/" + r.id);
    },
    viewFeatureResult(r) {
      this.featureResults = [];
      this.results = [];
      this.searchTerm = "";
      this.$router.push(r.url);
    },
    search: debounce(function () {
      if (this.searchTerm.length > 2) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/search", {
            search: this.searchTerm,
          })
          .then(({ data }) => {
            this.results = data;
          });
      } else {
        this.results = [];
        this.featureResults = [];
      }
    }, 200),
    close() {
      this.results = [];
      this.searchTerm = "";
    },
  },
};
</script>

<style>
.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-purple {
  background-color: rgba(108, 74, 158, 0.09) !important;
  border: none !important;
  outline: none !important;
}

.form-control-purple:focus {
  border-color: transparent;
  outline: none !important;
  /* box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25); */
}

.input-group-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  height: 100%;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: transparent !important;
  border: none !important;
}
</style>
