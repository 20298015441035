<template>
  <div
    class="modal fade show"
    id="changeTimezone"
    role="dialog"
    aria-labelledby="changeTimezoneTitle"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="changeTimezoneTitle">
            Timezone change detected
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal" data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col text-center">
              <p class="text-primary"><i class="fa fa-user-clock fa-4x"></i></p>
              <p>
                We have detected that your System Timezone has changed since you
                last visited us.
              </p>
              <p>
                Detected Timezone: <b>{{ this.detectedTz }}</b>
              </p>
              <p>
                From this point all times will be displayed in your local time.
                If this is incorrect, please adjust your system time settings.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["user"],

  data() {
    return {
      detectedTz: null,
    };
  },
  methods: {
    checkTimezone() {
      this.detectedTz = moment.tz.guess();
      if (this.user) {
        if (
          this.detectedTz !== this.user.timezone ||
          !this.user.clinic.timezone
        ) {
          if (this.detectedTz !== this.user.timezone) {
            //$("#changeTimezone").modal("show");
          }
          this.$axios
            .post(process.env.VUE_APP_API_URL + "/settings/timezone", {
              timezone: this.detectedTz,
            })
            .then(({ data }) => {
              //this.$EventBus.$emit("alert", data);
            });
        }
      }
    },
  },
  mounted() {
    this.checkTimezone();
  },
};
</script>

<style>
</style>