import Vue from "vue";

var pluralize = require("pluralize");

Vue.filter("pluralize", (str) => {
  return pluralize(str);
});

Vue.filter("formatDate", (date) => {
  if (date) {
    return moment.utc(date).local().format("LLL");
  }
});

Vue.filter("formatDateTime", (date) => {
  if (date) {
    return moment.utc(date).local().format("LLL");
  }
});

Vue.filter("timeSince", (date) => {
  if (date) {
    const duration = moment.duration(moment().diff(date));
    const diff = duration.asDays().toFixed();
    if (diff > 1) {
      return diff + " days ago";
    } else if (diff > 31) {
      return duration.asMonths().toFixed() + " months ago";
    } else if (diff > 365) {
      return duration.asMonths().toFixed() + " years ago";
    } else {
      return duration.asDays().toFixed() + " days ago";
    }
  } else {
    return "Never";
  }
});

Vue.filter("formatDateNoTime", (date) => {
  if (date) {
    return moment(date).format("LL");
  }
});

Vue.filter("removeUnderscores", (status) => {
  if (status) {
    return status.replace("_", " ");
  }
});

Vue.filter("validDomain", (domain) => {
  var re = new RegExp(
    /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
  );
  return domain.match(re);
});
