var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"\n    onboarding-tour-step-2\n    col-md-3 col-lg-2\n    d-md-block\n    bg-light\n    sidebar\n    collapse\n    overflow-auto\n  ",attrs:{"id":"sidebarMenu"}},[_c('div',{staticClass:"position-sticky pt-4"},[_c('ul',{staticClass:"nav nav-pills flex-column"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('dashboard')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/dashboard"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-th-large"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Dashboard")])])])],1)]),_vm._m(0),_c('ul',{staticClass:"nav nav-pills flex-column"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('emails') ? 'router-link-exact-active' : '',attrs:{"to":"/emails"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-inbox"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Emails")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('enquiries')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/enquiries"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-envelope-open"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Enquiries")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('events') ? 'router-link-exact-active' : '',attrs:{"to":"/events"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-calendar"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Events")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('tasks') ? 'router-link-exact-active' : '',attrs:{"to":"/tasks"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-tasks"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Tasks")])])])],1)]),_vm._m(1),_c('ul',{staticClass:"nav nav-pills flex-column"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('clients') ? 'router-link-exact-active' : '',attrs:{"to":"/clients"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-users"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Clients")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('orders') ? 'router-link-exact-active' : '',attrs:{"to":"/orders"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-clipboard-list"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Orders")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('consultations')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/consultations"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-calendar-check"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Consultations")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('forms') ? 'router-link-exact-active' : '',attrs:{"to":"/forms"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-align-center"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Forms")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('health-reports')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/health-reports"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-file-alt"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Health Reports")])])])],1),(_vm.$store.getters['auth/user'].email.includes('@swandoola.com'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('document-builder')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/document-builder"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-file-alt"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Document Builder*")])])])],1):_vm._e()]),_vm._m(2),_c('ul',{staticClass:"nav nav-pills flex-column"},[(_vm.$can('view accounts'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('accounts') ? 'router-link-exact-active' : '',attrs:{"to":"/accounts"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-coins"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Accounts")])])])],1):_vm._e(),(_vm.$can('view reporting'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('reports') ? 'router-link-exact-active' : '',attrs:{"to":"/reports"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-chart-bar"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Reporting")])])])],1):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('resources')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/resources"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-paperclip"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Resources")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('marketing')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/marketing"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-bullhorn"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Marketing")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings') ? 'router-link-exact-active' : '',attrs:{"to":"/settings"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-cogs"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Settings")])])])],1),(_vm.$store.getters['auth/user'].email.includes('@swandoola.com'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('domains') ? 'router-link-exact-active' : '',attrs:{"to":"/domains"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-globe"})]),_c('div',{staticClass:"col my-auto text-start"},[_vm._v("Domains (beta)")])])])],1):_vm._e(),(_vm.$store.getters['auth/user'].email.includes('@swandoola.com'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('marketing-suite')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/marketing-suite"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-rocket"})]),_c('div',{staticClass:"col my-auto text-start"},[_vm._v("Marketing Suite (beta)")])])])],1):_vm._e(),(
          _vm.$store.getters['auth/user'].email.includes('alex@swandoola.com') ||
          _vm.$store.getters['auth/user'].email.includes('justin@swandoola.com') ||
          _vm.$store.getters['auth/user'].email.includes('justin@amchara.com')
        )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('app-builder')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/app-builder/general"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-mobile"})]),_c('div',{staticClass:"col my-auto text-start"},[_vm._v("App Builder (beta)")])])])],1):_vm._e()]),_vm._m(3),_c('ul',{staticClass:"nav nav-pills flex-column"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('webinars') ? 'router-link-exact-active' : '',attrs:{"to":"/webinars"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-tv"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Webinars")])])])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"\n        sidebar-heading\n        d-flex\n        justify-content-between\n        align-items-center\n        px-3\n        mt-3\n        mb-0\n        text-muted\n      "},[_c('span',[_vm._v("My Swandoola")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"\n        sidebar-heading\n        d-flex\n        justify-content-between\n        align-items-center\n        px-3\n        mt-3\n        mb-0\n        text-muted\n      "},[_c('span',[_vm._v("Client Management")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"\n        sidebar-heading\n        d-flex\n        justify-content-between\n        align-items-center\n        px-3\n        mt-3\n        mb-0\n        text-muted\n      "},[_c('span',[_vm._v("Clinic Management")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"\n        sidebar-heading\n        d-flex\n        justify-content-between\n        align-items-center\n        px-3\n        mt-3\n        mb-0\n        text-muted\n      "},[_c('span',[_vm._v("Swandoola Access")])])}]

export { render, staticRenderFns }