<template>
  <div class="my-auto dropdown ms-3 me-auto" v-if="user">
    <button
      id="navbarSupportDropdown"
      data-bs-toggle="dropdown"
      aria-expanded="true"
      class="btn btn-light text-primary"
      v-tooltip.bottom="'Support'"
    >
      <i class="far fa-life-ring"></i>
    </button>

    <div
      class="dropdown-menu dropdown-menu-end mt-2 py-0 shadow"
      style="z-index: 1021; min-width: 20rem; max-width: 20rem"
      aria-labelledby="navbarSupportDropdown"
    >
      <div class="bg-success pt-2">
        <div class="row mx-0">
          <div class="col">
            <div class="">
              <input
                type="text"
                v-model="faqSearch"
                @changed="searchFaqs"
                class="form-control mb-2"
                placeholder="Search our Frequently Asked Questions..."
              />
            </div>
          </div>
        </div>
      </div>

      <div class="bg-light pt-2">
        <div class="row mx-2">
          <div class="col">
            <div class="row">
              <div class="col">
                <b class="ps-1" v-if="faqSearch.length < 4">Popular Articles</b>
                <b class="ps-1" v-else>Search Results</b>

                <busy :visible="loading" />

                <ul class="ms-4 mt-1">
                  <li v-for="faq in searchResults.slice(0, 8)" class="mb-2">
                    <a
                      :href="'https://support.swandoola.com/faqs/' + faq.slug"
                      class="
                        d-block
                        text-primary text-nowrap
                        overflow-hidden
                        small
                      "
                      style="text-overflow: ellipsis"
                    >
                      {{ faq.title }}
                    </a>
                  </li>
                </ul>
                <div class="row" v-if="searchResults.length === 0 && !loading">
                  <div class="col text-center">
                    <p>No articles found for those search terms.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white">
        <div class="dropdown-divider my-0"></div>
        <a
          class="dropdown-item py-2"
          href="https://support.swandoola.com/faqs"
          target="_blank"
        >
          View all FAQs
        </a>
        <div class="dropdown-divider my-0"></div>
        <router-link class="dropdown-item py-2" to="/support-requests/create">
          Create a Support Request
        </router-link>

        <router-link class="dropdown-item py-2" to="/support-requests">
          View Support Requests
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
export default {
  props: ["user"],

  data() {
    return {
      loading: true,
      faqSearch: "",
      searchResults: [],
      popularArticles: [],
    };
  },

  computed: {},
  methods: {
    fetchPopularArticles() {
      this.loading = true;
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/support/faqs/popular")
        .then(({ data }) => {
          this.popularArticles = data;
          this.searchResults = this.popularArticles;
          this.loading = false;
        });
    },
    searchFaqs: debounce(function () {
      this.searchResults = [];
      this.loading = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/support/faqs/search", {
          search: this.faqSearch,
        })
        .then(({ data }) => {
          this.searchResults = data;
          this.loading = false;
        });
    }),
    showSupportRequestModal() {
      this.$emit("showSupportRequestModal");
    },
  },
  mounted() {
    this.fetchPopularArticles();
  },
  watch: {
    faqSearch(newVal, oldVal) {
      if (newVal.length > 3) {
        this.searchFaqs();
      } else {
        this.searchResults = this.popularArticles;
      }
    },
  },
  filters: {},
};
</script>