var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"\n    onboarding-tour-step-2\n    col-md-3 col-lg-2\n    d-md-block\n    bg-light\n    sidebar\n    collapse\n    overflow-auto\n  ",attrs:{"id":"sidebarMenu"}},[_c('div',{staticClass:"position-sticky pt-4"},[_c('ul',{staticClass:"nav nav-pills flex-column"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/dashboard"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-arrow-left"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Exit settings")])])])],1)]),_vm._m(0),_c('ul',{staticClass:"nav nav-pills flex-column"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/community')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/community"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-globe"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Community")])])])],1)]),(_vm.$can('manage clinic'))?_c('h6',{staticClass:"\n        sidebar-heading\n        d-flex\n        justify-content-between\n        align-items-center\n        px-3\n        mt-3\n        mb-0\n        text-muted\n      "},[_c('span',[_vm._v("Clinic")])]):_vm._e(),_c('ul',{staticClass:"nav nav-pills flex-column"},[(_vm.$can('manage clinic'))?[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/client-portal')
                ? 'router-link-exact-active'
                : '',attrs:{"to":"/settings/client-portal"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-users"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Client Portal")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/clinic')
                ? 'router-link-exact-active'
                : '',attrs:{"to":"/settings/clinic"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-clinic-medical"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Clinic Management")])])]),(_vm.$route.path.includes('settings/clinic'))?_c('div',[_c('a',{staticClass:"btn btn-light w-100 fst-italic",attrs:{"href":"#details"}},[_vm._v("Clinic Details")]),_c('a',{staticClass:"btn btn-light w-100 fst-italic",attrs:{"href":"#logo"}},[_vm._v("Clinic Logo")]),_c('a',{staticClass:"btn btn-light w-100 fst-italic",attrs:{"href":"#timezone"}},[_vm._v("Clinic Timezone")]),_c('a',{staticClass:"btn btn-light w-100 fst-italic",attrs:{"href":"#appointments"}},[_vm._v("Clinic Appointment Settings")]),_c('a',{staticClass:"btn btn-light w-100 fst-italic",attrs:{"href":"#invoices"}},[_vm._v("Clinic Invoice Settings")]),_c('a',{staticClass:"btn btn-light w-100 fst-italic",attrs:{"href":"#tax-rates"}},[_vm._v("Clinic Tax Rates")])]):_vm._e()],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/roles-permissions')
                ? 'router-link-exact-active'
                : '',attrs:{"to":"/settings/roles-permissions"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-lock"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Roles & Permissions")])])])],1)]:_vm._e(),(_vm.$can('manage clinic services'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/services') ||
            _vm.$route.path.includes('settings/packages')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/services"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-box-full"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Clinic Services")])])])],1):_vm._e(),(_vm.$can('manage clinic terms'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/terms')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/terms"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-handshake"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Clinic Terms")])])])],1):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/tracker')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/tracker"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-mind-share"})]),_c('div',{staticClass:"col my-auto text-start"},[_vm._v("Health Tracker Templates")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/integrations')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/integrations"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-plug"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Integrations")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/timelines')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/timelines"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-list-alt"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Timelines")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/phraseology')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/phraseology"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-quote-right"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Phraseology")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/products')
            || _vm.$route.path.includes('settings/products/categories')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/products"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-tag"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Products")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/supplements')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/supplements"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-pills"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Supplements")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/tests')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/tests"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-vial"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Tests")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/user')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/user"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-user"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("User")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/exports')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/exports"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-download"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Data Exports")])])])],1)],2),_vm._m(1),_c('ul',{staticClass:"nav nav-pills flex-column"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/snippets-appointment-booker')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/snippets-appointment-booker"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-code"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Appointment Booker")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/snippets-enquiry-form')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/snippets-enquiry-form"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-code"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Enquiry Form")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('settings/snippets-health-form')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/settings/snippets-health-form"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-code"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Health Form")])])])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"\n        sidebar-heading\n        d-flex\n        justify-content-between\n        align-items-center\n        px-3\n        mt-3\n        mb-0\n        text-muted\n      "},[_c('span',[_vm._v("Settings")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"\n        sidebar-heading\n        d-flex\n        justify-content-between\n        align-items-center\n        px-3\n        mt-3\n        mb-0\n        text-muted\n      "},[_c('span',[_vm._v("Website Snippets")])])}]

export { render, staticRenderFns }