<template>
  <div class="emailModal">
    <div class="modal fade" id="child-profile" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div
            class="modal-header d-flex justify-space-between align-items-center"
          >
            <h5 class="mb-0 text-primary">Create Childrens Profile</h5>
          </div>

          <!-- Modal Body -->
          <div
            class="modal-body bg-white"
            style="max-height: 75vh; overflow: scroll"
          >
            <!--  -->
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="first_name">First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="first_name"
                    id="first_name"
                    placeholder="First Name"
                  />
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label for="last_name">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="last_name"
                    id="last_name"
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label for="dob">Date of Birth</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="dob"
                    id="dob"
                    placeholder="Date of Birth"
                  />
                </div>
              </div>
            </div>
            <!--  -->
          </div>

          <!-- Modal Actions -->
          <div class="modal-footer py-2 bg-white">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
            <button
              class="btn btn-primary ms-auto"
              :disabled="first_name == '' || last_name == ''"
              @click="createChildProfile"
            >
              Create Child Profile
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["client"],
  data() {
    return {
      first_name: "",
      last_name: "",
      dob: "",
    };
  },
  methods: {
    createChildProfile() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/create-child-profile",
          {
            first_name: this.first_name,
            last_name: this.last_name,
            dob: this.dob,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$emit("childProfileCreated", data.client);
        });
    },
  },
  mounted() {
    // $("#child-profile").modal("show");
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
  },
  components: {},
};
</script>

<style>
</style>
