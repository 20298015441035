import Vue from "vue";
import VueRouter from "vue-router";

import billing from "@/router/billing";
import payments from "./payments";
import settings from "@/router/settings";
import store from "@/store";
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home-page" */ "../views/pages/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/auth/Login.vue"),
  },
  {
    path: "/login/:token",
    name: "LoginSplash",
    component: () => import(/* webpackChunkName: "login-splash" */ "../views/auth/LoginSplash.vue"),
  },

  //
  {
    path: "/dashboard",
    name: "DashboardView",
    component: () =>
      import(/* webpackChunkName: "dashboard-view" */ "../views/areas/dashboard/Index.vue"),
    meta: {
      auth: true,
    },
  },

  //
  {
    path: "/notifications",
    name: "NotificationsIndex",
    component: () =>
      import(
        /* webpackChunkName: "notifications-index" */
        "../views/areas/notifications/Index.vue"
      ),
    meta: {
      auth: true,
    },
  },

  // Emails
  {
    path: "/emails",
    name: "Emails",
    component: () =>
      import(/* webpackChunkName: "emails-index" */ "../views/areas/emails/Index.vue"),
    meta: {
      auth: true,
    },
  },

  // Clients
  {
    path: "/clients",
    name: "ClientsIndex",
    component: () =>
      import(/* webpackChunkName: "clients-index" */ "../views/areas/clients/Index.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/exports",
    name: "ClientDataExports",
    component: () =>
      import(
        /* webpackChunkName: "client-data-exports" */
        "../views/areas/clients/ClientDataExports.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/import",
    name: "ClientImport",
    component: () =>
      import(/* webpackChunkName: "client-import" */ "../views/areas/clients/CsvImport.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id",
    name: "ClientView",
    component: () =>
      import(
        /* webpackChunkName: "client-view" */
        "../views/areas/clients/views/ClientSummary.vue"
      ),
    meta: {
      auth: true,
    },
  },
  // Client views
  {
    path: "/clients/:id/client-summary",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-summary" */
        "../views/areas/clients/views/ClientSummary.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/client-details",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-details" */
        "../views/areas/clients/views/ClientDetails.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/terms",
    name: "",
    component: () =>
      import(/* webpackChunkName: "client-view-terms" */ "../views/areas/clients/views/Terms.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/allergies",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-allergies" */
        "../views/areas/clients/views/Allergies.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/forms",
    name: "",
    component: () =>
      import(/* webpackChunkName: "client-view-forms" */ "../views/areas/clients/views/Forms.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/emails",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-emails" */
        "../views/areas/clients/views/Emails.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/consultations",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-consultations" */
        "../views/areas/clients/views/Consultations.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/orders",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-orders" */
        "../views/areas/clients/views/Orders.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/timeline",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-timeline" */
        "../views/areas/clients/views/Timeline.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/notes",
    name: "",
    component: () =>
      import(/* webpackChunkName: "client-view-notes" */ "../views/areas/clients/views/Notes.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/files",
    name: "",
    component: () =>
      import(/* webpackChunkName: "client-view-files" */ "../views/areas/clients/views/Files.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/resources",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-resources" */
        "../views/areas/clients/views/Resources.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/online-programmes",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-online-programmes" */
        "../views/areas/clients/views/OnlineProgrammes.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/health-reports",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-health-reports" */
        "../views/areas/clients/views/HealthReports.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/health-tracker",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-health-tracker" */
        "../views/areas/clients/views/HealthTracker.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/planner",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-planner" */
        "../views/areas/clients/views/Planner.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/diary",
    name: "",
    component: () =>
      import(/* webpackChunkName: "client-view-diary" */ "../views/areas/clients/views/Diary.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/activity-log",
    name: "",
    component: () =>
      import(
        /* webpackChunkName: "client-view-activity-log" */
        "../views/areas/clients/views/ActivityLog.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/client-resource/:resource",
    name: "ClientResourceView",
    component: () =>
      import(
        /* webpackChunkName: "client-resource-view" */
        "../views/areas/clients/ClientResource.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/clients/:id/create-custom-order/:cartId",
    name: "ClientRestoreCustomOrder",
    component: () =>
      import(
        /* webpackChunkName: "client-restore-custom" */ "../views/areas/orders/CreateCustomOrder.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/clients/:id/create-custom-order",
    name: "ClientCreateCustomOrder",
    component: () =>
      import(
        /* webpackChunkName: "client-create-custom" */ "../views/areas/orders/CreateCustomOrder.vue"
      ),
    meta: {
      auth: true,
    },
  },

  // Orders
  {
    path: "/orders",
    name: "OrdersIndex",
    component: () =>
      import(/* webpackChunkName: "orders-index" */ "../views/areas/orders/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/orders/:id",
    name: "OrderView",
    component: () => import(/* webpackChunkName: "order-view" */ "../views/areas/orders/View.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/orders/:id/payment-card",
    name: "OrderPaymentCard",
    component: () =>
      import(
        /* webpackChunkName: "order-payment-card" */
        "../views/areas/orders/PractitionerOrderCardPayment.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/orders/:id/payment-cash",
    name: "OrderPaymentCash",
    component: () =>
      import(
        /* webpackChunkName: "order-payment-cash" */
        "../views/areas/orders/PractitionerOrderCashPayment.vue"
      ),
    meta: {
      auth: true,
    },
  },

  // Suppliers
  {
    path: "/supplier-payments",
    name: "SupplierPayments",
    component: () =>
      import(
        /* webpackChunkName: "supplier-payments" */ "../views/areas/supplier-payments/Index.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/system-check",
    name: "SystemCheck",
    component: () =>
      import(/* webpackChunkName: "system-check" */ "../views/areas/consultations/Preflight.vue"),
  },

  // Consultations
  {
    path: "/consultations",
    name: "ConsultationsIndex",
    component: () =>
      import(
        /* webpackChunkName: "consultations-index" */
        "../views/areas/consultations/Index.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/consultations/:id",
    name: "ConsultationView",
    component: () =>
      import(/* webpackChunkName: "consultation-view" */ "../views/areas/consultations/View.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/consultations/video/:id",
    name: "ConsultationVideoView",
    component: () =>
      import(
        /* webpackChunkName: "consultation-video-view" */
        "../views/areas/consultations/Video.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/consultations/payment/:id",
    name: "ConsultationPaymentFrontEndView",
    component: () =>
      import(
        /* webpackChunkName: "consultation-payment-view" */
        "../views/pages/ConsultationPayment.vue"
      ),
  },
  // Unsubscribe from marketing
  {
    path: "/marketing/unsubscribe/:userId",
    name: "UnsubscribeThanksView",
    component: () =>
      import(/* webpackChunkName: "unsubscribe-view" */ "../views/pages/UnsubscribeThanks.vue"),
  },

  // Forms
  {
    path: "/forms",
    name: "FormsIndex",
    component: () => import(/* webpackChunkName: "forms-index" */ "../views/areas/forms/Index.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/forms/:id",
    name: "FormView",
    component: () => import(/* webpackChunkName: "form-view" */ "../views/areas/forms/View.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/forms/:id/preview",
    name: "FormPreView",
    component: () =>
      import(/* webpackChunkName: "form-preview" */ "../views/areas/forms/PreviewForm.vue"),
    meta: {
      auth: true,
    },
  },
  // Accounts
  {
    path: "/accounts",
    name: "AccountsIndex",
    component: () =>
      import(/* webpackChunkName: "accounts-index" */ "../views/areas/accounts/Index.vue"),
    meta: {
      auth: true,
    },
  },
  // Reports
  {
    path: "/reports",
    name: "ReportsIndex",
    component: () =>
      import(/* webpackChunkName: "reports-index" */ "../views/areas/reports/Layout.vue"),
    meta: {
      auth: true,
    },
  },
  // Resources
  {
    path: "/resources",
    name: "ResourcesIndex",
    component: () =>
      import(/* webpackChunkName: "resources-index" */ "../views/areas/resources/Layout.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/resources/:id",
    name: "ResourcesView",
    component: () =>
      import(/* webpackChunkName: "resources-index" */ "../views/areas/resources/View.vue"),
    meta: {
      auth: true,
    },
  },

  // Tasks
  {
    path: "/tasks",
    name: "TasksIndex",
    component: () => import(/* webpackChunkName: "tasks-index" */ "../views/areas/tasks/Index.vue"),
    meta: {
      auth: true,
    },
  },

  // Tasks
  {
    path: "/feedback",
    name: "FeedbackIndex",
    component: () =>
      import(/* webpackChunkName: "feedback-index" */ "../views/areas/feedback/Index.vue"),
    meta: {
      auth: true,
    },
  },

  // Enquiries
  {
    path: "/enquiries",
    name: "EnquiriesIndex",
    component: () =>
      import(/* webpackChunkName: "enquiries-index" */ "../views/areas/enquiries/Index.vue"),
    meta: {
      auth: true,
    },
  },

  // Events
  {
    path: "/events",
    name: "EventsIndex",
    component: () =>
      import(/* webpackChunkName: "events-index" */ "../views/areas/events/Index.vue"),
    meta: {
      auth: true,
    },
  },

  //Document Builder
  {
    path: "/document-builder",
    name: "DocumentBuilderIndex",
    component: () =>
      import(
        /* webpackChunkName: "document-builder-index" */
        "../views/areas/document-builder/Index.vue"
      ),
    meta: {
      auth: true,
    },
  },

  //Document Builder
  {
    path: "/document-builder/:templateId",
    name: "EditorIndex",
    component: () =>
      import(
        /* webpackChunkName: "document-builder-index" */
        "../views/areas/document-builder/Index.vue"
      ),
    meta: {
      auth: true,
    },
  },

  // Health Reports
  {
    path: "/health-reports",
    name: "HealthReportsIndex",
    component: () =>
      import(
        /* webpackChunkName: "health-reports-index" */
        "../views/areas/health-reports/Index.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/health-reports/:clientId/:templateId",
    name: "HealthReportClientView",
    component: () =>
      import(
        /* webpackChunkName: "health-report-client-view" */
        "../views/areas/health-reports/ClientView.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/health-reports/:id",
    name: "HealthReportView",
    component: () =>
      import(/* webpackChunkName: "health-report-view" */ "../views/areas/health-reports/View.vue"),
    meta: {
      auth: true,
    },
  },

  // Online Programmes
  // {
  //   path: '/online-programmes',
  //   name: 'OnlineProgrammesIndex',
  //   component: () => import( /* webpackChunkName: "online-programmes-index" */ '../views/areas/online-programmes/Index.vue'),
  //   meta: {
  //     auth: true
  //   },
  // },
  // {
  //   path: '/online-programmes/schedule',
  //   name: 'OnlineProgrammeSchedule',
  //   component: () => import( /* webpackChunkName: "online-programmes-schedule" */ '../views/areas/online-programmes/Schedule.vue'),
  //   meta: {
  //     auth: true
  //   },
  // },
  // {
  //   path: '/online-programmes/schedule/:id',
  //   name: 'OnlineProgrammeScheduleView',
  //   component: () => import( /* webpackChunkName: "online-programmes-schedule-view" */ '../views/areas/online-programmes/ScheduleView.vue'),
  //   meta: {
  //     auth: true
  //   },
  // },
  // {
  //   path: '/online-programmes/schedule-item/:id',
  //   name: 'OnlineProgrammeScheduleItemView',
  //   component: () => import( /* webpackChunkName: "online-programmes-schedule-item-view" */ '../views/areas/online-programmes/ScheduleItemView.vue'),
  //   meta: {
  //     auth: true
  //   },
  // },
  // {
  //   path: '/online-programmes/schedule-item/:id/broadcast',
  //   name: 'OnlineProgrammeScheduleItemBroadcast',
  //   component: () => import( /* webpackChunkName: "online-programmes-schedule-item-broadcast" */ '../views/areas/online-programmes/ScheduleItemBroadcast.vue'),
  //   meta: {
  //     auth: true
  //   },
  // },
  // {
  //   path: '/online-programmes/register/:id',
  //   name: 'OnlineProgrammeRegisterView',
  //   component: () => import( /* webpackChunkName: "online-programmes-register-view" */ '../views/areas/online-programmes/Register.vue'),
  // },
  // Webinars
  {
    path: "/webinars",
    name: "WebinarsIndex",
    component: () =>
      import(/* webpackChunkName: "webinars-index" */ "../views/areas/webinars/Index.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/webinars/:id",
    name: "WebinarView",
    component: () =>
      import(/* webpackChunkName: "webinar-view" */ "../views/areas/webinars/View.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/webinars/:id/purchase",
    name: "WebinarPurchase",
    component: () =>
      import(/* webpackChunkName: "webinar-view" */ "../views/areas/webinars/Purchase.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/webinars/:id/media/:mediaId",
    name: "MediaView",
    component: () =>
      import(/* webpackChunkName: "webinar-media-view" */ "../views/areas/webinars/MediaView.vue"),
    meta: {
      auth: true,
    },
  },

  // Marketing
  {
    path: "/marketing",
    name: "MarketingIndex",
    component: () =>
      import(/* webpackChunkName: "marketing-index" */ "../views/areas/marketing/Index.vue"),
    meta: {
      auth: true,
    },
  },

  // Marketing Suite (beta)
  {
    path: "/marketing-suite",
    name: "MarketingSuiteIndex",
    component: () =>
      import(
        /* webpackChunkName: "marketing-suite-index" */
        "../views/areas/marketing-suite/marketing/Index.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/marketing-lists",
    name: "MarketingListsIndex",
    component: () =>
      import(
        /* webpackChunkName: "marketing-lists-index" */
        "../views/areas/marketing-suite/marketing-lists/Index.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/marketing-lists/:id",
    name: "MarketingListsView",
    component: () =>
      import(
        /* webpackChunkName: "marketing-lists-view" */
        "../views/areas/marketing-suite/marketing-lists/View.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/marketing-sendouts",
    name: "MarketingSendoutsIndex",
    component: () =>
      import(
        /* webpackChunkName: "marketing-sendouts-index" */
        "../views/areas/marketing-suite/marketing-sendouts/Index.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/marketing-sendouts/create",
    name: "MarketingSendoutsCreate",
    component: () =>
      import(
        /* webpackChunkName: "marketing-sendouts-create" */
        "../views/areas/marketing-suite/marketing-sendouts/Create.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/marketing-sendouts/:id",
    name: "MarketingSendoutsView",
    component: () =>
      import(
        /* webpackChunkName: "marketing-sendouts-view" */
        "../views/areas/marketing-suite/marketing-sendouts/View.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/marketing-email-templates",
    name: "MarketingListsIndex",
    component: () =>
      import(
        /* webpackChunkName: "marketing-email-templates-index" */
        "../views/areas/marketing-suite/marketing-email-templates/Index.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/marketing/client-emails",
    name: "ClientEmailsView",
    component: () =>
      import(
        /* webpackChunkName: "marketing-email-client-emails-view" */
        "../views/areas/marketing/areas/email/ClientEmails.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/marketing/email-series",
    name: "EmailSeriesView",
    component: () =>
      import(
        /* webpackChunkName: "marketing-email-email-series-view" */
        "../views/areas/marketing/areas/email/EmailSeries.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/marketing/templates",
    name: "EmailTemplatesView",
    component: () =>
      import(
        /* webpackChunkName: "marketing-email-templates-view" */
        "../views/areas/marketing/areas/email/Templates.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/marketing/signature",
    name: "EmailTemplatesView",
    component: () =>
      import(
        /* webpackChunkName: "marketing-email-signature-view" */
        "../views/areas/marketing/areas/email/Signature.vue"
      ),
    meta: {
      auth: true,
    },
  },

  // Domains

  // Authorised Domains
  {
    path: "/domains",
    name: "DomainsIndex",
    component: () =>
      import(/* webpackChunkName: "domains-index" */ "../views/areas/domains/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/domains/create",
    name: "DomainsCreate",
    component: () =>
      import(/* webpackChunkName: "domains-create" */ "../views/areas/domains/Create.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/domains/:id",
    name: "DomainsView",
    component: () =>
      import(/* webpackChunkName: "domains-view" */ "../views/areas/domains/View.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/announcements/:id",
    name: "AnnouncementView",
    component: () =>
      import(/* webpackChunkName: "announcement-view" */ "../views/areas/announcements/View"),
    meta: {
      auth: true,
    },
  },

  // App Builder
  {
    path: "/app-builder",
    name: "AppBuilderIndex",
    component: () =>
      import(/* webpackChunkName: "app-builder-index" */ "../views/areas/app-builder/Index.vue"),
    meta: {
      auth: true,
    },
  },

  {
    path: "/app-builder/general",
    name: "AppBuilderGeneral",
    component: () =>
      import(
        /* webpackChunkName: "app-builder-general" */
        "../views/areas/app-builder/views/General.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/app-builder/features",
    name: "AppBuilderFeatures",
    component: () =>
      import(
        /* webpackChunkName: "app-builder-features" */
        "../views/areas/app-builder/views/Features.vue"
      ),
    meta: {
      auth: true,
    },
  },

  {
    path: "/app-builder/navigation",
    name: "AppBuilderNavigation",
    component: () =>
      import(
        /* webpackChunkName: "app-builder-navigation" */
        "../views/areas/app-builder/views/Navigation.vue"
      ),
    meta: {
      auth: true,
    },
  },

  // Support requests
  {
    path: "/support-requests",
    name: "SupportRequestsIndex",
    component: () =>
      import(
        /* webpackChunkName: "support-requests-index" */
        "../views/areas/support-requests/Index.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/support-requests/create",
    name: "SupportRequestsCreate",
    component: () =>
      import(
        /* webpackChunkName: "support-requests-create" */
        "../views/areas/support-requests/Create.vue"
      ),
    meta: {
      auth: true,
    },
  },
  {
    path: "/support-requests/:id",
    name: "SupportRequestsView",
    component: () =>
      import(
        /* webpackChunkName: "support-requests-view" */
        "../views/areas/support-requests/View.vue"
      ),
    meta: {
      auth: true,
    },
  },

  ...settings,
  ...payments,
  ...billing,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = store.getters["auth/authed"];
  if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
    next("/login");
    return;
  }

  if (to.path == "/login" || to.path == "/") {
    if (loggedIn) {
      next("/dashboard");
    }
  }
  next();
});

export default router;
