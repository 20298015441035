<template>
  <div class="modal-body">
    <div class="notification-container" v-if="notifications.length == 0">
      <busy :visible="loadingNotifications" />
      <div v-if="!loadingNotifications" class="alert alert-warning">
        We don't have anything to show you right now! But when we do, we'll be
        sure to let you know.
      </div>
    </div>

    <!-- List Of Notifications -->
    <div class="notification-container" v-if="hasNotifications">
      <div class="notification" v-for="notification in notifications" v-bind:key="notification.id">
        <template v-if="!notification.isCC || (notification.isCC && show_cc)">
          <!-- Notification Icon -->
          <figure>
            <span class="fa-stack text-white fa-2x">
              <i class="far fa-circle fa-stack-2x"></i>
              <i
                :class="[
                  'fad',
                  'fa-stack-1x',
                  'text-primary',
                  notification.icon,
                ]"
              ></i>
            </span>
          </figure>

          <!-- Notification -->
          <div class="notification-content">
            <div class="meta">
              <div
                class="date text-dark"
                :class="!notification.read ? 'font-italic text-primary' : ''"
              >
                {{ notification.created_at | relative }}
              </div>
            </div>
            <div>
              <span v-if="notification.isCC" class="badge bg-secondary"
                >CC</span
              >
            </div>
            <div class="notification-body">
              <p class="small">{{ notification.body }}</p>
            </div>

            <!-- Notification Action -->
            <a
              @click="gotoLocation(notification.action_url)"
              class="btn btn-sm btn-outline-primary"
              v-if="notification.action_text"
            >
              {{ notification.action_text }}
            </a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show_cc"],
  data() {
    return {
      loadingNotifications: true,
      notifications: [],
    };
  },
  methods: {
    markNotificationsAsRead() {
      if (!this.hasUnreadNotifications) {
        return;
      }

      this.$axios.put(process.env.VUE_APP_API_URL + "/notifications/all-read", {
        notifications: _.map(this.notifications, "id"),
      });

      this.$EventBus.$emit("notificationsRead");
    },
    fetchNotifications() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/notifications")
        .then(({ data }) => {
          this.notifications = data.notifications;
          this.loadingNotifications = false;
        });
    },
    gotoLocation(loc) {
      this.$router.push(loc);
      if (loc.indexOf("clients") && loc.indexOf("#")) {
        loc = loc.substring(loc.indexOf("#") + 1);
        this.$EventBus.$emit("showClientTab", loc);
      }
      $("#modal-notifications").modal("hide");
    },
  },
  mounted() {
    this.fetchNotifications();

    const _this = this;
    this.$EventBus.$on("showNotifications", function () {
      $("#modal-notifications").modal("show");
      _this.markNotificationsAsRead();
    });
  },
  computed: {
    hasNotifications: function () {
      return this.notifications && this.notifications.length > 0;
    },
    unreadNotificationsCount: function () {
      if (this.notifications) {
        return _.filter(this.notifications, function (notification) {
          return !notification.read;
        }).length;
      }

      return 0;
    },
    hasUnreadNotifications: function () {
      return this.unreadNotificationsCount > 0;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  filters: {
    relative(date) {
      return moment(date).local().locale("en-short").fromNow();
    },
  },
};
</script>
