<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <label for="address_1">Address 1</label>
        <input
          type="text"
          v-model="form.address_1"
          id="address_1"
          class="form-control"
          placeholder="Address 1"
        />
      </div>
      <div class="col">
        <label for="address_2">Address 2</label>
        <input
          type="text"
          v-model="form.address_2"
          id="address_2"
          class="form-control"
          placeholder="Address 2"
        />
      </div>
    </div>
    <!--  -->

    <div class="row mb-3">
      <div class="col">
        <label for="town">Town</label>
        <input type="text" v-model="form.town" id="town" class="form-control" placeholder="Town" />
      </div>
      <div class="col">
        <label for="city">City</label>
        <input type="text" v-model="form.city" id="city" class="form-control" placeholder="City" />
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <label for="post_code">Post Code</label>
        <input
          type="text"
          v-model="form.post_code"
          id="post_code"
          class="form-control"
          placeholder="Post Code"
        />
      </div>
      <div class="col">
        <label for="county">County</label>
        <input
                type="text"
                v-model="form.county"
                id="county"
                class="form-control"
                placeholder="County"
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6">
        <label for="country">Country</label>
        <country-select v-model="form.country"></country-select>
      </div>
    </div>
  </div>
</template>

<script>
import CountrySelect from "../../../globals/CountrySelect";
export default {
  data() {
    return {
      form: {
        address_1: "",
        address_2: "",
        town: "",
        city: "",
        post_code: "",
        county: "",
        country: "United Kingdom"
      }
    };
  },
  watch: {
    form: {
      handler: function(val) {
        this.$emit("updated", this.form);
      },
      deep: true
    }
  },
  components: {
    CountrySelect
  }
};
</script>

<style>
</style>