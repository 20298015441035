"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.default = function (key) {
  return {
    get: function get() {
      return key.split(".").reduce(function (pValue, cValue) {
        return pValue[cValue];
      }, this.$root.stash);
    },
    set: function set(value) {
      var path = key.split(".");
      var length = path.length - 1;
      var stash = this.$root.stash;

      for (var i = 0; i < length; i++) {
        if (stash.hasOwnProperty(path[i])) {
          stash = stash[path[i]];
        }
      }

      stash[path[i]] = value;
    },
  };
};
