import Vue from 'vue'

import VueClipboard from 'vue2-clipboard'
Vue.use(VueClipboard)

window._ = require("lodash");

global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

import * as bootstrap from "bootstrap/dist/js/bootstrap.esm";
Vue.prototype.$bootstrap = bootstrap;
import "@/assets/styles/app.scss";