export default {
  // Globally available user object
  user: null,
  token: null,
  showTutorial: false,
  showSupportModal: false,
  addClient: false,
  clientInView: null,
  timelineEditing: false,
  // Document Template builder
  loadedTemplate: null,
  templateItems: [],
  currentSelectedRow: null,
  currentSelectedCol: null,
  currentSelectedColKey: null,
  currentSelectedItem: null,
  itemBeingDragged: null,

  //Reports
  reportData: {
    allForms: [],
    allAgreements: [],
    Orders: [],
  },

  // Events stuff
  fullSizedCalendarApi: null,
  fullSizedCalendarApiDefaultView: "timeGridWeek",
  miniCalendarApi: null,
  showWeekends: false,
  selectedEventFilter: "all",
  selectedEvent: null,
  newEventSelectedDates: null,
  showAddNewEvent: false,
  calendarFilters: {},
  miniCalendarSelectedDate: null,
  viewingEvent: null,

  //Cart
  cart: null,

  //Currencies
  currencies: [],

  // Gmail integration
  gmail: {
    loading: true,
    signedIn: false,
    emailAddress: "",
  },
};
