<template>
  <div>
    <div
      class="modal docked docked-right"
      id="modal-notifications"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header d-block h-auto py-2">
            <div class="btn-group mb-2">
              <button
                class="btn btn-sm"
                @click="viewBackgroundTasks = !viewBackgroundTasks"
                :class="
                  !viewBackgroundTasks ? 'btn-primary' : 'btn-outline-primary'
                "
              >
                <i class="far fa-bell me-1"></i>
                Notifications
              </button>
              <button
                class="btn btn-sm"
                @click="viewBackgroundTasks = !viewBackgroundTasks"
                :class="
                  viewBackgroundTasks ? 'btn-primary' : 'btn-outline-primary'
                "
              >
                <i class="far fa-user-clock me-1"></i>
                Background Tasks
              </button>
            </div>
            <div class="row">
              <div class="col-md-6 ">
                <div class="text-center d-block">
                  <span
                    @click="viewAll"
                    class="cursor-pointer text-primary hover:underline"
                  >
                    View All
                  </span>
                </div>
              </div>
              <div class="col-md-6">
                <toggle-button v-model="hideCC"
                               class="my-auto"
                               :color="'#03006b'" />
                <label class="custom-control-label ms-1  my-autotext-capitalize">
                  Hide CC
                </label>
              </div>
            </div>
          </div>

          <notifications-view
            v-if="!viewBackgroundTasks"
            class="mt-3"
            :show_cc="!hideCC"
          ></notifications-view>

          <background-tasks-view v-else></background-tasks-view>

          <!-- Modal Actions -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            >
              {{ "Close" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundTasksView from "./notifications-partials/BackgroundTasksView";
import NotificationsView from "./notifications-partials/NotificationsView";
export default {
  data() {
    return {
      viewBackgroundTasks: false,
      hideCC: false,
    };
  },
  methods: {
    viewAll() {
      $("#modal-notifications").modal("hide");
      this.$router.push("/notifications");
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  components: {
    BackgroundTasksView,
    NotificationsView,
  },
};
</script>
