export default [
  {
    path: '/payments',
    name: 'PaymentsIndex',
    component: () => import( /* webpackChunkName: "payments-index" */ '../views/areas/payments/Index.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/payments/payouts',
    name: 'PayoutsIndex',
    component: () => import( /* webpackChunkName: "payouts-index" */ '../views/areas/payments/Payouts.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/payments/history',
    name: 'PaymentsHistory',
    component: () => import( /* webpackChunkName: "payments-history" */ '../views/areas/payments/History.vue'),
    meta: {
      auth: true
    },
  },
];
