<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" id="manageWorkingHoursPresetsModal">
      <div class="modal-dialog modal modal-dialog-centered" role="document">
        <div class="modal-content bg-white">
          <div class="modal-body">
            <div v-if="!showAdd">
              <p v-if="loading" class="text-center">Loading...</p>
              <div v-else>
                <p v-if="presets.length === 0" class="text-center">No presets found</p>
                <div v-else>
                  <div class="card bg-light mb-2" v-for="p in presets">
                    <div class="card-body py-2">
                      <div class="d-flex">
                        <span class="my-auto">
                          {{p.name}}
                        </span>
                        <button class="btn btn-xs btn-outline-danger ms-auto ms-auto"
                                @click.prevent="deletePreset(p)">
                          <i class="far fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-end">
                    <button class="btn btn-sm btn-outline-primary mt-1" @click="showAdd=true">
                      <i class="far fa-plus me-1" />
                      Create new Preset
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div v-else>
              <WorkingHoursForm
                  :preset="editPreset"
                  @saved="showAdd=false; editPreset=false; fetchPresets()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WorkingHoursForm from "./WorkingHoursForm";
export default {
  components: {WorkingHoursForm},
  props: [],
  data() {
    return {
      loading: true,
      presets: [],
      showAdd: false,
      editPreset: null,
    };
  },
  methods: {
    fetchPresets() {
      this.loading = true;
      this.$axios.get(process.env.VUE_APP_API_URL + '/working-hours-presets')
          .then(({data}) => {
            this.presets = data.filter((item) => item.clinic_id === this.$store.getters['auth/user'].clinic_id);
            this.loading = false;
          })
    },
    deletePreset(preset) {
      this.$axios.delete(process.env.VUE_APP_API_URL + '/working-hours-presets/' + preset.id)
          .then(({data}) => {
            this.$EventBus.$emit('refreshWorkingHourPresets');
            this.$EventBus.$emit("alert", data);
          })
    }
  },
  mounted () {
    this.fetchPresets()
    this.$EventBus.$on('refreshWorkingHourPresets', this.fetchPresets);
  },
  beforeDestroy() {
    this.$EventBus.$off('refreshWorkingHourPresets', this.fetchPresets);
  }
};
</script>