<template>
  <div id="app">
    <div class="p-2 sticky-top bg-warning" v-if="updateExists && !blockedRoute">
      <div class="container-fluid">
        <div class="row">
          <div class="col my-auto">
            <p class="text-primary fw-bold mb-0">
              Important: There is an update available for the Swandoola
              Practitioner Portal! To receive the latest updates please reload
              the page or click the button.
            </p>
          </div>
          <div class="col-auto ml-auto my-auto">
            <button @click="refreshApp" class="btn btn-sm btn-light fw-bold">
              <i class="far fa-redo-alt me-2"></i>Click to update
            </button>
          </div>
        </div>
      </div>
    </div>
    <front-end-nav
      v-if="$route.fullPath === '/' || $route.fullPath === '/login'"
      :path="$route.fullPath"
    ></front-end-nav>

    <main-nav
      v-if="
        $route.fullPath != '/events' &&
        $route.fullPath != '/' &&
        $route.fullPath != '/login'
      "
    ></main-nav>

    <!-- New -->
    <div class="container-fluid">
      <div class="row">
        <side-bar-nav
          v-if="
            user &&
            $router.currentRoute.fullPath != '/events' &&
            !$router.currentRoute.fullPath.includes('/task-boards') &&
            $router.currentRoute.fullPath != '/task-boards' &&
            !$router.currentRoute.fullPath.includes('/pipeline') &&
            !$router.currentRoute.fullPath.includes('/login') &&
            !$router.currentRoute.fullPath.includes('/settings') &&
            !$stash.clientInView &&
            !$router.currentRoute.fullPath.includes('/consultations/video')
          "
        />

        <settings-side-bar-nav
          v-if="
            user &&
            $router.currentRoute.fullPath.includes('/settings') &&
            !$router.currentRoute.fullPath.includes('/consultations/video')
          "
        />

        <app-builder-side-bar-nav
          v-if="
            user &&
            $router.currentRoute.fullPath.includes('/app-builder') &&
            !$stash.clientInView &&
            !$router.currentRoute.fullPath.includes('/consultations/video')
          "
        />

        <client-profile-side-bar
          v-if="
            user &&
            $stash.clientInView &&
            !$router.currentRoute.fullPath.includes('/consultations/video') &&
            $router.currentRoute.fullPath.includes('/clients')
          "
          :id="$stash.clientInView"
          :key="$route.fullPath"
        />

        <main
          :class="
            user &&
            $router.currentRoute.fullPath != '/events' &&
            !$router.currentRoute.fullPath.includes('/task-boards') &&
            $router.currentRoute.fullPath != '/task-boards' &&
            !$router.currentRoute.fullPath.includes('/pipeline') &&
            !$router.currentRoute.fullPath.includes('/login') &&
            !$router.currentRoute.fullPath.includes('/consultations/video')
              ? 'ms-sm-auto col-md-9 col-lg-10'
              : 'col-12'
          "
        >
          <router-view
            :key="$route.fullPath"
            id="router-view"
            class="pt-4"
            v-if="
              (user && user.onboarding_status === 'onboarded') ||
              !user ||
              $router.currentRoute.fullPath.includes('/login') ||
              $router.currentRoute.fullPath.includes('/webinars') ||
              $router.currentRoute.fullPath.includes('/billing')
            "
          />

          <pre-demo-view
            v-else-if="user && user.onboarding_status === 'pre_demo'"
          />
        </main>
      </div>
    </div>

    <!-- End New -->

    <snack-bar-alert v-if="user"></snack-bar-alert>

    <notifications v-if="user" :user="user"></notifications>

    <manage-working-hours-modal v-if="user" />

    <timezone-check :user="user"></timezone-check>
    <browser-warning></browser-warning>

    <add-client
      :user="user"
      v-if="$stash.addClient"
      @closed="$stash.addClient = !$stash.addClient"
      @added="clientAdded"
    ></add-client>
  </div>
</template>
<script>
import MainNav from "./views/layouts/nav/MainNav";
import SideBarNav from "./views/layouts/nav/SideBarNav";
import SettingsSideBarNav from "./views/layouts/nav/SettingsSideBarNav";
import AppBuilderSideBarNav from "./views/layouts/nav/AppBuilderSideBarNav";
import ClientProfileSideBar from "./views/layouts/nav/ClientProfileSideBar";
import FrontEndNav from "./views/layouts/FrontEndNav";
import SnackBarAlert from "./views/layouts/SnackBarAlert";
import TimezoneCheck from "./views/layouts/TimezoneCheck";
import BrowserWarning from "./views/globals/BrowserWarning";
import Notifications from "./views/layouts/Notifications";
import AddAnythingMenu from "./views/layouts/AddAnythingMenu";
import PreDemoView from "@/views/areas/settings/areas/PreDemoView";
import ManageWorkingHoursModal from "./views/components/working-hours/ManageWorkingHoursModal";
import AddClient from "@/views/components/clients/AddClient";

export default {
  metaInfo: {
    title: "Swandoola Practitioners Portal",
    titleTemplate: "%s | Swandoola - everything in one place",
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
      blockedRoute: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },

    displayMenus() {
      if (this.$router.currentRoute.fullPath.includes("messages")) {
        return false;
      } else if (this.$router.currentRoute.fullPath.includes("events")) {
        return false;
      } else if (this.$router.currentRoute.fullPath.includes("documents")) {
        return false;
      } else if (this.$router.currentRoute.fullPath.includes("webinars")) {
        return false;
      } else if (this.$router.currentRoute.fullPath.includes("dashboard")) {
        return false;
      } else if (this.$router.currentRoute.fullPath.includes("settings")) {
        return false;
      } else if (this.$router.currentRoute.fullPath.includes("reports")) {
        return false;
      } else if (
        this.$router.currentRoute.fullPath.includes("client-video-chat")
      ) {
        return false;
      } else if (this.$router.currentRoute.fullPath.includes("app-builder")) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    clientAdded() {
      this.$EventBus.$emit("clientAdded");
    },
    fetchCurrencies() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/currencies")
        .then(({ data }) => {
          this.$stash.currencies = data;
        });
    },
    checkBlockedRoutes() {
      let route = location.href;
      if (route.includes("/consultations/video/")) {
        this.blockedRoute = true;
      } else {
        this.blockedRoute = false;
      }
    },
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
  },
  beforeCreate() {
    this.$stash.clientInView = null;
  },
  created() {
    this.fetchCurrencies();

    if (this.user) {
      this.$store.dispatch("auth/refreshUser");
    }

    // Checking for service worker updates
    document.addEventListener("applicationUpdated", this.showRefreshUI, {
      once: true,
    });

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }

    // Refresh user data
    this.$EventBus.$on("refreshUser", this.$store.dispatch("auth/refreshUser"));
  },
  mounted() {
    this.$EventBus.$on("showAddNewClient", (e) => {
      this.$stash.addClient = true;
      this.$nextTick(function () {
        $("#addNewClient").modal("show");
      });
    });
  },
  components: {
    ManageWorkingHoursModal,
    PreDemoView,
    MainNav,
    SideBarNav,
    SettingsSideBarNav,
    AppBuilderSideBarNav,
    ClientProfileSideBar,
    SnackBarAlert,
    TimezoneCheck,
    BrowserWarning,
    Notifications,
    AddAnythingMenu,
    FrontEndNav,
    AddClient,
  },
};
</script>
<style lang="scss">
</style>
