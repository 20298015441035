<template>
  <div class="container-fluid py-3">
    <div class="row">
      <div class="col mx-auto">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col text-center">
                <img src="@/assets/images/brand/navy-gold-med.png" alt="Swandoola" width="75" />
              </div>
            </div>
            <h3 class="text-center">Swandoola Practitioners Platform</h3>
            <hr class="my-4" />

            <div class="row text-center">
              <div class="col-md-7">
                <img src="https://swandoola.com/images/headers/screens.png" alt="Swandoola" width="80%" />
              </div>
              <div class="col pt-3 pe-4">
                <h5 style="line-height: 32px">
                  The complete client management system for UK Nutritionists & Functional Medicine Practitioners with everything in one place...
                </h5>
                <div class="row my-3">
                  <div class="col-auto ms-auto me-3">
                    <img
                        src="https://swandoola.com/images/people/jo_gamble.jpg"
                        width="75px"
                        height="75px"
                        class="rounded-circle bg-light"
                        alt="Jo Gamble - Swandoola Practitioner"
                    />
                  </div>

                  <div class="col-auto me-auto ms-3">
                    <img
                        src="https://swandoola.com/images/people/sue_camp.jpg"
                        width="75px"
                        height="75px"
                        class="rounded-circle bg-light"
                        alt="Sue Camp - Swandoola Practitioner"
                    />
                  </div>
                </div>

                <div class="row text-center">
                  <div class="col">
                    <div class="col">
                      <h5 class="mb-4">As recommended by Jo Gamble & Sue Camp</h5>
                      <a href="https://swandoola.com/demo-request" class="btn btn-primary btn-block fw-bold mb-4">
                        <i class="far fa-star me-2"></i>REQUEST A DEMO
                      </a>
                      <router-link :to="'/billing'" class="btn btn-info btn-block px-4">
                        <i class="far fa-bullseye-pointer me-2"></i>Subscription Plans
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="row text-center">
              <div class="col">
                <div class="card border-0 shadow-none">
                  <div class="card-body p-2">
                    <i class="far fa-users fa-3x text-primary mb-4"></i>
                    <p>Complete client management from enquiry to consultation to health reporting</p>
                  </div>
                </div>
              </div>

              <!--  -->
              <div class="col">
                <div class="card border-0 shadow-none">
                  <div class="card-body p-2">
                    <i class="far fa-notes-medical fa-3x text-primary mb-4"></i>
                    <p>Bespoke client questionnaires, intake forms and health timeline generation</p>
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="col">
                <div class="card border-0 shadow-none">
                  <div class="card-body p-2">
                    <i class="far fa-wand-magic fa-3x text-primary mb-4"></i>
                    <p>Form input autocomplete for easy re-use of items, sentences and phrases</p>
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="col">
                <div class="card border-0 shadow-none">
                  <div class="card-body p-2">
                    <i class="far fa-mobile-alt fa-3x text-primary mb-4"></i>
                    <p>Companion app for iOS and Android, client & task management included</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["path"],
  data() {
    return {
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  components: {
  },
};
</script>

<style>
</style>
