<template>
  <form @submit.prevent="savePreset">
    <div class="row mb-3">
      <div class="col">
        <label>Enter a name for your preset</label>
        <input
          type="text"
          class="form-control"
          v-model="name"
          placeholder="Enter a name..."
          required
        />
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <div class="card bg-white">
          <div class="card-body pt-2">
            <WeekScheduleSelect v-model="preset" :is-preset-selection="true" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-right text-end">
        <button class="btn btn-primary">
          <i class="far fa-copy me-1 me-1"></i>
          Save Preset
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
   components: {
     WeekScheduleSelect: () => import('./WeekScheduleSelect.vue')
   },
  name: "WorkingHoursForm",
  props: ["preset"],
  data() {
    return {
      name: "",
    };
  },
  methods: {
    savePreset() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/working-hours-presets",
          {
            name: this.name,
            preset: this.preset,
            clinic_id: this.$store.getters['auth/user'].clinic_id
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$EventBus.$emit("refreshWorkingHourPresets");
          this.$emit("saved");
          $("#saveWorkingHoursPresetModal").modal("hide");
        });
    },
  },
  mounted() {},
};
</script>