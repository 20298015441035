<template>
  <div class="sticky-top noprint">
    <header
      class="
        navbar navbar-light
        bg-white
        flex-md-nowrap
        p-0
        shadow-sm
        my-auto
        w-100
      "
    >
      <router-link
        class="navbar-brand col col-md-3 col-lg-2 me-0 px-2 px-md-4"
        to="/"
      >
        <img src="@/assets/images/brand/full-logo.svg" alt="Swandoola"
      /></router-link>

      <!-- Support -->
      <support-dropdown
        class="d-md-none"
        :user="user"
        @showSupportRequestModal="$stash.showSupportModal = true"
      ></support-dropdown>
      <!-- End support -->

      <!-- Notifications -->
      <button
        @click="showNotifications"
        class="d-md-none btn btn-light mx-2"
        v-tooltip:bottom="'Notifications'"
      >
        <i
          v-if="loadingNotifications"
          class="far fa-spin fa-spinner text-primary"
        />
        <span v-else class="text-primary fw-bold">
          <i class="far fa-bell me-2" />
          {{ notificationsCount }}
        </span>
      </button>

      <button
        class="navbar-toggler d-md-none collapsed p-2 me-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle Navigation"
      >
        <i class="far fa-bars text-primary fa-1x"></i>
      </button>
      <div class="d-none d-md-flex flex-shrink-0 mb-2 mb-md-0 me-3">
        <!-- <button
          id="tutroialButton"
          class="btn ms-3 me-3"
          v-tooltip:bottom="'Tutorial'"
          :class="
            $stash.showTutorial
              ? 'moving-gradient-button text-white'
              : 'btn-light text-primary'
          "
          @click="toggleTutorial"
        >
          <i id="tutroialButtonIcon" class="far fa-shoe-prints" />
        </button> -->
        <button
          class="btn btn-light text-primary fw-bold"
          href="#"
          data-toggle="modal"
          data-target="#addNewClient"
          @click="$stash.addClient = !$stash.addClient"
        >
          <i class="far fa-plus me-2"></i>New Client
        </button>
      </div>

      <div class="position-relative w-100 h-100 mt-2 mt-md-0">
        <nav-search></nav-search>
      </div>

      <div class="d-none d-md-flex flex-shrink-0 me-md-3">
        <router-link
          to="/tasks"
          class="btn btn-light ms-3"
          v-tooltip:bottom="'Tasks'"
        >
          <span class="text-primary fw-bold">
            <i class="far fa-tasks me-2" />
            {{ taskCount }}
          </span>
        </router-link>

        <router-link
          to="/events"
          class="btn btn-light ms-3"
          v-tooltip:bottom="'Events'"
        >
          <span class="text-primary fw-bold">
            <i class="far fa-calendar me-2" />
            {{ eventCount }}
          </span>
        </router-link>

        <!-- Notifications -->
        <button
          @click="showNotifications"
          class="btn btn-light ms-3"
          v-tooltip:bottom="'Notifications'"
        >
          <i
            v-if="loadingNotifications"
            class="far fa-spin fa-spinner text-primary"
          />
          <span v-else class="text-primary fw-bold">
            <i class="far fa-bell me-2" />
            {{ notificationsCount }}
          </span>
        </button>

        <!-- End notifications -->

        <!-- Support -->
        <support-dropdown
          :user="user"
          @showSupportRequestModal="$stash.showSupportModal = true"
        ></support-dropdown>

        <!-- End support -->
      </div>
      <div class="d-none d-md-block flex-shrink-0 dropdown mx-3" v-if="user">
        <a
          href="#"
          class="d-block link-dark text-decoration-none dropdown-toggle ms-2"
          id="dropdownUser2"
          data-bs-toggle="dropdown"
          aria-expanded="true"
        >
          <img
            :src="user.profile_pic_url"
            alt="user"
            width="32"
            height="32"
            class="rounded-circle me-2"
          />
          {{ user.name }}
        </a>
        <div
          class="dropdown-menu text-small dropdown-menu-end"
          aria-labelledby="dropdownUser2"
          data-popper-placement="bottom-end"
          st
        >
          <router-link class="dropdown-item" to="/settings"
            >Settings</router-link
          >
          <div class="dropdown-divider"></div>
          <router-link
            class="dropdown-item"
            to="/payments"
            v-if="user && $can('manage payment settings')"
            >Payments</router-link
          >
          <router-link
            class="dropdown-item"
            to="/billing"
            v-if="user && $can('manage billing')"
            >Billing</router-link
          >
          <div
            class="dropdown-divider"
            v-if="user && $can('manage billing')"
          ></div>
          <a
            class="dropdown-item"
            href="https://support.swandoola.com/faqs"
            target="_blank"
            >FAQs</a
          >
          <a
            class="dropdown-item"
            href="https://swandoola.com/privacy"
            target="_blank"
            >Privacy</a
          >
          <a
            class="dropdown-item"
            href="https://swandoola.com/terms"
            target="_blank"
            >Terms</a
          >
          <div class="dropdown-divider"></div>

          <div
            class="dropdown-item cursor-pointer"
            @click="logout()"
            v-if="user"
          >
            <i class="far fa-portal-exit me-2" />Logout
          </div>
        </div>
      </div>
    </header>
    <support-request-modal
      v-if="user && $stash.showSupportModal"
      :user="user"
      @close="$stash.showSupportModal = false"
    ></support-request-modal>
  </div>
</template>

<script>
import NavSearch from "./NavSearch";
import SupportRequestModal from "./partials/SupportRequestModal";
import SupportDropdown from "./partials/SupportDropdown";

export default {
  data() {
    return {
      taskCount: 0,
      eventCount: 0,
      enquiriesCount: 0,
      loadingNotifications: false,
      notificationsCount: 0,
      showSupport: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path != "/") {
        var w = window.innerWidth;
        if (w <= 768) {
          const menuToggle = document.getElementById("sidebarMenu");
          const bsCollapse = new this.$bootstrap.Collapse(menuToggle, {
            toggle: false,
          });
          bsCollapse.hide();
          window.scrollTo(0, 0);
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
    cart() {
      return this.$stash.cart;
    },
  },
  methods: {
    toggleTutorial() {
      this.$stash.showTutorial = !this.$stash.showTutorial;
      if (!this.$stash.showTutorial) {
        this.$tours["onboardingTour"].start();
      } else {
        this.$tours["onboardingTour"].stop();
      }
    },
    logout() {
      // localStorage.removeItem("user");
      // if (localStorage.getItem("googleLogin")) {
      //   var auth2 = gapi.auth2.getAuthInstance();
      //   auth2.signOut().then(function () {
      //     console.log("User signed out.");
      //   });
      // }
      // localStorage.removeItem("googleLogin");
      // location.href = "/";

      this.$store.dispatch("auth/logout");
    },
    refreshCart() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/cart/" +
            this.$stash.cart.id +
            "/refresh"
        )
        .then(({ data }) => {
          this.$stash.cart = data;
        });
    },
    fetchTasksCount() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/tasks/fetch-user-tasks-count")
        .then(({ data }) => {
          this.taskCount = data;
        });
    },
    fetchEventsCount() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/events/fetch-user-events-count")
        .then(({ data }) => {
          this.eventCount = data;
        });
    },
    fetchEnquiriesCount() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/enquiries/api/fetch-count")
        .then(({ data }) => {
          this.enquiriesCount = data;
        });
    },
    fetchNotificationsCount() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/notifications/unread")
        .then(({ data }) => {
          this.notificationsCount = data.notifications.length;
        });
    },
    goToCart() {
      this.$router.push("/cart");
    },
    showNotifications() {
      this.$EventBus.$emit("showNotifications");
    },
    clientAdded() {
      this.$EventBus.$emit("clientAdded");
    },
  },
  mounted() {
    if (this.cart) {
      this.refreshCart();
    }
    if (this.user) {
      this.fetchTasksCount();
      this.fetchEventsCount();
      this.fetchEnquiriesCount();
      this.fetchNotificationsCount();
    }

    var _this = this;
    this.$EventBus.$on("productsAddedToCart", function (incrementBy = 1) {
      _this.cart.quantity += incrementBy;
    });

    this.$EventBus.$on("emptyCart", function (incrementBy = 1) {
      _this.cart.quantity = 0;
    });

    this.$EventBus.$on("notificationsRead", function () {
      _this.notificationsCount = 0;
    });

    this.$EventBus.$on("tasksUpdated", (e) => {
      this.fetchTasksCount();
    });

    this.$EventBus.$on("taskAssigned", (e) => {
      this.fetchTasksCount();
    });

    this.$EventBus.$on("taskCompleted", (e) => {
      this.fetchTasksCount();
    });
  },
  filters: {
    acronym(name) {
      var str = name;
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(""); // JSON

      return acronym;
    },
  },
  components: {
    NavSearch,
    SupportRequestModal,
    SupportDropdown,
  },
};
</script>

<style>
.notification-pill {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 99px;
  width: 4.5rem;
  height: auto;
  background-color: #f4f6f6;
  color: #495a5a;
  font-weight: 600;
  font-size: 0.8rem;
}

.nav-icon-sized {
  font-size: 1.5rem;
}

.notification-pill {
  font-size: 0.75rem;
}

.notification-pill i {
  font-size: 1rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  /* background-color: rgba(0, 0, 0, 0.25); */
}

@media (min-width: 768px) {
  .navbar-brand {
    box-shadow: inset -1px 0 0 rgba(255, 255, 255, 0.25);
  }
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}
</style>
