<template>
  <nav
    id="sidebarMenu"
    class="
      onboarding-tour-step-2
      col-md-3 col-lg-2
      d-md-block
      bg-light
      sidebar
      collapse
      overflow-auto
    "
  >
    <div class="position-sticky pt-4">
      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('dashboard')
                ? 'router-link-exact-active'
                : ''
            "
            to="/dashboard"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-th-large"></i>
              </div>
              <div class="col my-auto">Dashboard</div>
            </div>
          </router-link>
        </li>
      </ul>

      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-3
          mb-0
          text-muted
        "
      >
        <span>My Swandoola</span>
      </h6>

      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('emails') ? 'router-link-exact-active' : ''
            "
            to="/emails"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-inbox"></i>
              </div>
              <div class="col my-auto">Emails</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('enquiries')
                ? 'router-link-exact-active'
                : ''
            "
            to="/enquiries"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-envelope-open"></i>
              </div>
              <div class="col my-auto">Enquiries</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('events') ? 'router-link-exact-active' : ''
            "
            to="/events"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-calendar"></i>
              </div>
              <div class="col my-auto">Events</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('tasks') ? 'router-link-exact-active' : ''
            "
            to="/tasks"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-tasks"></i>
              </div>
              <div class="col my-auto">Tasks</div>
            </div>
          </router-link>
        </li>
      </ul>

      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-3
          mb-0
          text-muted
        "
      >
        <span>Client Management</span>
      </h6>

      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('clients') ? 'router-link-exact-active' : ''
            "
            to="/clients"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-users"></i>
              </div>
              <div class="col my-auto">Clients</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('orders') ? 'router-link-exact-active' : ''
            "
            to="/orders"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-clipboard-list"></i>
              </div>
              <div class="col my-auto">Orders</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('consultations')
                ? 'router-link-exact-active'
                : ''
            "
            to="/consultations"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-calendar-check"></i>
              </div>
              <div class="col my-auto">Consultations</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('forms') ? 'router-link-exact-active' : ''
            "
            to="/forms"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-align-center"></i>
              </div>
              <div class="col my-auto">Forms</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('health-reports')
                ? 'router-link-exact-active'
                : ''
            "
            to="/health-reports"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-file-alt"></i>
              </div>
              <div class="col my-auto">Health Reports</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item"
        v-if="$store.getters['auth/user'].email.includes('@swandoola.com')">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('document-builder')
                ? 'router-link-exact-active'
                : ''
            "
            to="/document-builder"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-file-alt"></i>
              </div>
              <div class="col my-auto">Document Builder*</div>
            </div>
          </router-link>
        </li>
      </ul>

      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-3
          mb-0
          text-muted
        "
      >
        <span>Clinic Management</span>
        <!-- <a class="link-secondary" href="#">
          <i class="far fa-plus-circle"></i>
        </a> -->
      </h6>

      <ul class="nav nav-pills flex-column">
        <li class="nav-item" v-if="$can('view accounts')">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('accounts') ? 'router-link-exact-active' : ''
            "
            to="/accounts"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-coins"></i>
              </div>
              <div class="col my-auto">Accounts</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item" v-if="$can('view reporting')">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('reports') ? 'router-link-exact-active' : ''
            "
            to="/reports"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-chart-bar"></i>
              </div>
              <div class="col my-auto">Reporting</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('resources')
                ? 'router-link-exact-active'
                : ''
            "
            to="/resources"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-paperclip"></i>
              </div>
              <div class="col my-auto">Resources</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('marketing')
                ? 'router-link-exact-active'
                : ''
            "
            to="/marketing"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-bullhorn"></i>
              </div>
              <div class="col my-auto">Marketing</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings') ? 'router-link-exact-active' : ''
            "
            to="/settings"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-cogs"></i>
              </div>
              <div class="col my-auto">Settings</div>
            </div>
          </router-link>
        </li>

        <li
          class="nav-item"
          v-if="$store.getters['auth/user'].email.includes('@swandoola.com')"
        >
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('domains') ? 'router-link-exact-active' : ''
            "
            to="/domains"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-globe"></i>
              </div>
              <div class="col my-auto text-start">Domains (beta)</div>
            </div>
          </router-link>
        </li>

        <li
          class="nav-item"
          v-if="$store.getters['auth/user'].email.includes('@swandoola.com')"
        >
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('marketing-suite')
                ? 'router-link-exact-active'
                : ''
            "
            to="/marketing-suite"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-rocket"></i>
              </div>
              <div class="col my-auto text-start">Marketing Suite (beta)</div>
            </div>
          </router-link>
        </li>

        <li
          class="nav-item"
          v-if="
            $store.getters['auth/user'].email.includes('alex@swandoola.com') ||
            $store.getters['auth/user'].email.includes('justin@swandoola.com') ||
            $store.getters['auth/user'].email.includes('justin@amchara.com')
          "
        >
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('app-builder')
                ? 'router-link-exact-active'
                : ''
            "
            to="/app-builder/general"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-mobile"></i>
              </div>
              <div class="col my-auto text-start">App Builder (beta)</div>
            </div>
          </router-link>
        </li>
      </ul>

      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-3
          mb-0
          text-muted
        "
      >
        <span>Swandoola Access</span>
      </h6>

      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('webinars') ? 'router-link-exact-active' : ''
            "
            to="/webinars"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-tv"></i>
              </div>
              <div class="col my-auto">Webinars</div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
</style>
