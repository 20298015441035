export default class Model {
  // Define a base url for a REST API

  // orderBy (...columns) {
  //   return super.orderBy(...columns)
  // }

  // sortBy (...columns) {
  //   return super.orderBy(...columns)
  // }
}
