import router from "@/router";
import User from "@/models/User";

//
const state = {
  authed: false,
  user: null,
  token: null,
};

const mutations = {
  setAuthed(state, payload) {
    state.authed = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
};

const actions = {
  async login({ commit, dispatch }, token) {
    const users = new User();
    const { data } = await users.login(token);
    if (data.success) {
      commit("setUser", data.user);
      commit("setToken", data.token.access_token);
      commit("setAuthed", true);

      router.push("/dashboard");
    } else {
      commit("setAuthed", false);
      commit("setUser", null);
      commit("setToken", null);
    }
  },
  async refreshUser({ commit, state }) {
    if (state.user && state.user.id) {
      const api = new User();
      const { data } = await api.show(state.user.id);
      commit("setUser", data);
    }
  },
  logout({ commit }) {
    commit("setAuthed", false);
    commit("setUser", null);
    commit("setToken", null);
    router.push("/");
  },
};

const getters = {
  authed(state) {
    return state.authed;
  },
  user(state) {
    return state.user;
  },
  token(state) {
    return state.token;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
