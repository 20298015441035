<template>
  <div
    class="modal"
    id="addNewClient"
    tabindex="-1"
    aria-labelledby="Add new client"
    aria-hidden="true"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <form @submit.prevent="addClient">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addNewClientTitle">Add new client</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col">
                <validation-provider name="first_name" rules="required">
                  <div slot-scope="{ errors }">
                    <label for="first_name">First Name</label>
                    <input
                      type="text"
                      v-model="form.first_name"
                      id="first_name"
                      class="form-control"
                      placeholder="First name"
                      required
                    />
                    <p class="mb-0 mt-1 text-danger">{{ errors[0] }}</p>
                  </div>
                </validation-provider>
              </div>
              <div class="col">
                <validation-provider name="last_name" rules="required">
                  <div slot-scope="{ errors }">
                    <label for="last_name">Last Name</label>
                    <input
                      type="text"
                      v-model="form.last_name"
                      id="last_name"
                      class="form-control"
                      placeholder="Last name"
                      required
                    />
                    <p class="mb-0 mt-1 text-danger">{{ errors[0] }}</p>
                  </div>
                </validation-provider>
              </div>
            </div>
            <!--  -->
            <div class="row mb-3">
              <div class="col">
                <validation-provider
                  name="email"
                  rules="required|email|email_unique"
                >
                  <div slot-scope="{ errors }">
                    <label for="email">Email Address</label>
                    <input
                      type="text"
                      v-model="form.email"
                      id="email"
                      class="form-control"
                      placeholder="Email address"
                    />
                    <p class="mb-0 mt-1 text-danger">{{ errors[0] }}</p>
                  </div>
                </validation-provider>
              </div>

              <div class="col">
                <label for="dob">Date of Birth</label>
                <date-input
                  id="dob"
                  :show-formatted="true"
                  v-model="form.dob"
                ></date-input>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-6">
                <label for="gender">Gender</label>
                <select id="gender" v-model="form.gender" class="form-control">
                  <option value>Select gender</option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="non-binary">Non-binary</option>
                </select>
              </div>

              <div class="col-6">
                <label for="title">Personal Title</label>
                <select id="title" v-model="form.title" class="form-control">
                  <option value>Select title</option>
                  <option value="mr">Mr</option>
                  <option value="mrs">Mrs</option>
                  <option value="miss">Miss</option>
                  <option value="ms">Ms</option>
                  <option value="mx">Mx</option>
                </select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-6">
                <validation-provider name="nhs_number" rules="nhs_number">
                  <div slot-scope="{ errors }">
                    <label for="nhs_number">NHS Number</label>
                    <input
                      type="text"
                      v-model="form.nhs_number"
                      id="nhs_number"
                      class="form-control"
                      maxlength="12"
                      placeholder="123 456 7890"
                    />
                    <p class="mb-0 mt-1 text-danger">{{ errors[0] }}</p>
                  </div>
                </validation-provider>
              </div>
            </div>

            <!--  -->
            <div class="row mb-3">
              <div class="col">
                <validation-provider name="phone" rules="phone">
                  <div slot-scope="{ errors }">
                    <label for="phone"> Phone Number (Primary) </label>
                    <phone-number-input
                      autofocus
                      v-model="form.phone"
                      default-country-code="GB"
                      :preferred-countries="[
                        'GB',
                        'MT',
                        'GR',
                        'ES',
                        'FR',
                        'BE',
                        'DE',
                      ]"
                      :clearable="true"
                    ></phone-number-input>
                    <p class="mb-0 mt-1 text-danger">{{ errors[0] }}</p>
                  </div>
                </validation-provider>
              </div>

              <div class="col">
                <validation-provider name="mobile" rules="phone">
                  <div slot-scope="{ errors }">
                    <label for="mobile"> Phone Number (Secondary) </label>
                    <phone-number-input
                      autofocus
                      v-model="form.mobile"
                      default-country-code="GB"
                      :preferred-countries="[
                        'GB',
                        'MT',
                        'GR',
                        'ES',
                        'FR',
                        'BE',
                        'DE',
                      ]"
                      :clearable="true"
                    ></phone-number-input>
                    <p class="mb-0 mt-1 text-danger">{{ errors[0] }}</p>
                  </div>
                </validation-provider>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col">
                <label for="note">
                  Client Note -
                  <small
                    >(add an optional note to this client for future
                    reference)</small
                  >
                </label>
                <textarea
                  v-model="form.note"
                  id="note"
                  rows="3"
                  class="form-control"
                ></textarea>
              </div>
            </div>

            <!--  -->
            <address-finder
              v-if="showAddressFields"
              @updated="addressUpdate"
            ></address-finder>

            <!--  -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary btn-sm me-auto"
              data-bs-dismiss="modal" data-dismiss="modal"
            >
              Close
            </button>
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                v-model="showAddressFields"
                class="custom-control-input"
                id="showAddressSwitch"
              />
              <label class="custom-control-label" for="showAddressSwitch"
                >Show address fields</label
              >
            </div>
            <input
              type="submit"
              class="btn btn-primary btn-sm ms-auto"
              value="Add Client"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AddressFinder from "./client-partials/AddressFinder";
import Datepicker from "vuejs-datepicker";
export default {
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        address_1: "",
        address_2: "",
        town: "",
        city: "",
        post_code: "",
        country: "",
        county: "",
        gender: "",
        note: "",
        dob: "",
      },
      showAddressFields: false,
    };
  },
  methods: {
    addressUpdate(data) {
      this.form.address_1 = data.address_1;
      this.form.address_2 = data.address_2;
      this.form.town = data.town;
      this.form.city = data.city;
      this.form.county = data.county;
      this.form.post_code = data.post_code;
      this.form.country = data.country;
    },
    addClient() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/clients/api", this.form)
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$EventBus.$emit("clientAdded");
          $("#addNewClient").modal("hide");
        });
    },
  },
  mounted() {
    $("#addNewClient").on("hidden.bs.modal", (e) => {
      this.$emit("closed");
    });
  },
  components: {
    AddressFinder,
    Datepicker,
  },
};
</script>

<style>
</style>
