import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Services
import "./services/core";
import "./services/mixins";
import "./services/filters";
import "./services/directives";

import moment from "moment";
window.moment = moment;
require("moment-timezone");
moment.locale("en-GB");

// Temporary for Vue2
import axios from "axios";
Vue.prototype.$axios = axios;
//

/*
vue-json-csv : https://github.com/Belphemur/vue-json-csv
*/
import JsonCSV from "vue-json-csv";
Vue.component("downloadCsv", JsonCSV);

import Busy from "@/components/Busy";
Vue.component("busy", Busy);

import Loading from "@/views/components/loader/Loading";
Vue.component("loading", Loading);

import VueCroppie from "vue-croppie";
Vue.use(VueCroppie);

import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

var VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
  hideModules: {
    table: true,
  },
});

import { ToggleButton } from "vue-js-toggle-button";
Vue.component("ToggleButton", ToggleButton);

Vue.config.productionTip = false;

// require("./validationRules");

// Validation Rules

import { extend } from "vee-validate";
import { required, email, min, url } from "vee-validate/dist/rules";

import PhoneNumber from "awesome-phonenumber";

extend("phone", {
  message(fieldName) {
    return `Please enter a valid phone number`;
  },
  validate(value) {
    return new Promise((resolve) => {
      let phone = new PhoneNumber(value);
      resolve({
        valid: phone.isValid(),
      });
    });
  },
});

// Add the required rule
extend("required", {
  ...required,
  message: "This field is required",
});

extend("email", {
  ...email,
  message: "Please provide a valid email address",
});

extend("min", {
  ...min,
  message: "Input not long enough",
});

import isURL from "validator/lib/isURL";

extend("url", {
  message(fieldName) {
    return `The URL is not valid eg. https://swandoola.com`;
  },
  validate(value) {
    return isURL(value, {
      require_tld: true,
    });
  },
});

extend("email_unique", {
  message(fieldName) {
    return `Email address already in use.`;
  },
  async validate(value) {
    const data = await Vue.prototype.$axios.get(
      process.env.VUE_APP_API_URL + "/clients/check-email/" + value
    );
    return data.data.valid === "true";
  },
});

extend("nhs_number", {
  message(fieldName) {
    return `NHS number not in correct format.`;
  },
  async validate(value) {
    return value.replace(/\D/g, "").length === 10;
  },
});

import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// End validation rules

import VueSnackbar from "vue-snack";
import "vue-snack/dist/vue-snack.min.css";

import VTooltip from "v-tooltip";
Vue.use(VTooltip);

Vue.use(VueSnackbar, {
  position: "bottom-right",
  time: 3000,
});

import EventBus from "./eventBus";
Vue.prototype.$EventBus = EventBus;

Vue.component("text-editor-modal", require("./views/globals/TextEditorModal.vue").default);
Vue.component("view-document-modal", require("./views/globals/ViewDocumentModal.vue").default);
Vue.component("phone-number-input", require("./views/globals/PhoneNumberInput.vue").default);
Vue.component("browser-warning", require("./views/globals/BrowserWarning.vue").default);
Vue.component("date-input", require("./views/globals/DateInput.vue").default);
Vue.component("timezone-check", require("./views/layouts/TimezoneCheck.vue").default);
Vue.component("alerts", require("./views/layouts/Alerts.vue").default);

// import { FcLoader } from "@fridaycollective/ui-toolkit/dist";
// Vue.component("fc-loader", FcLoader);

import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);

import VueStash from "@/services/stash";
import stash from "./store/stash";

Vue.use(VueStash);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);
Vue.$cookies.config("30d");

import permissions from "./mixins/permissions";
Vue.mixin(permissions);

import GmailIntegration from "./mixins/GmailIntegration";
Vue.mixin(GmailIntegration);

import Gapi from "vue-googleapis";
Vue.use(Gapi, {
  clientId: "506056635281-ql2vl3qvvnne3nmi3f2mcb61r5e0t7vu.apps.googleusercontent.com",
  discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"],
  scope: "https://mail.google.com/",
});

// Sentry logging
import * as Sentry from "@sentry/vue";

// Service worker for self-updating cache
import "./registerServiceWorker";

new Vue({
  router,
  store,
  data: {
    stash: { ...stash },
  },
  mounted() {},
  render: (h) => h(App),
}).$mount("#app");
