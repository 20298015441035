var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"\n    onboarding-tour-step-2\n    col-md-3 col-lg-2\n    d-md-block\n    bg-light\n    sidebar\n    collapse\n    overflow-auto\n  ",attrs:{"id":"sidebarMenu"}},[_c('div',{staticClass:"position-sticky pt-4"},[_c('ul',{staticClass:"nav nav-pills flex-column"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/dashboard"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-arrow-left"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Exit app builder")])])])],1)]),_vm._m(0),_c('ul',{staticClass:"nav nav-pills flex-column"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('app-builder/general')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/app-builder/general"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-rocket"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("General")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('app-builder/navigation')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/app-builder/navigation"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-link"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Navigation")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('app-builder/features')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/app-builder/features"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-stars"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("App Features")])])])],1)]),_vm._m(1),_c('ul',{staticClass:"nav nav-pills flex-column"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('app-builder/features')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/app-builder/features"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-stars"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Users")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('app-builder/reporting')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/app-builder/reporting"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-chart-line"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Reporting")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('app-builder/orders')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/app-builder/orders"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-shopping-cart"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Orders")])])])],1)]),_vm._m(2),_c('ul',{staticClass:"nav nav-pills flex-column"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('app-builder/look-and-feel')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/app-builder/look-and-feel"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-palette"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Look n' Feel")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('app-builder/pages')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/app-builder/pages"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-folder-tree"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Pages")])])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:_vm.$route.path.includes('app-builder/authentication')
              ? 'router-link-exact-active'
              : '',attrs:{"to":"/app-builder/authentication"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 my-auto"},[_c('i',{staticClass:"far fa-lock"})]),_c('div',{staticClass:"col my-auto"},[_vm._v("Authentication")])])])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"\n        sidebar-heading\n        d-flex\n        justify-content-between\n        align-items-center\n        px-3\n        mt-3\n        mb-0\n        text-muted\n      "},[_c('span',[_vm._v("App Settings")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"\n        sidebar-heading\n        d-flex\n        justify-content-between\n        align-items-center\n        px-3\n        mt-3\n        mb-0\n        text-muted\n      "},[_c('span',[_vm._v("User Management")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h6',{staticClass:"\n        sidebar-heading\n        d-flex\n        justify-content-between\n        align-items-center\n        px-3\n        mt-3\n        mb-0\n        text-muted\n      "},[_c('span',[_vm._v("Views")])])}]

export { render, staticRenderFns }