import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const requireContext = require.context("./modules", false, /.*\.js$/);
const modules = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ""), requireContext(file)])
  .reduce((allModules, [name, module]) => {
    allModules[name] = module.default;
    return allModules;
  }, {});

// Vue3
// export default createStore({
//   state: {},
//   getters: {},
//   mutations: {},
//   actions: {},
//   modules,
//   plugins: [createPersistedState()],
// });

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules,
  plugins: [createPersistedState()],
});
