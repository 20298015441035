export default [{
        path: "/settings",
        name: "SettingsIndex",
        component: () =>
            import ( /* webpackChunkName: "settings-index" */ "../views/areas/settings/Index.vue"),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/community",
        name: "SettingsCommunity",
        component: () =>
            import (
                /* webpackChunkName: "settings-community" */
                "../views/areas/settings/areas/Community.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/client-portal",
        name: "SettingsClientPortal",
        component: () =>
            import (
                /* webpackChunkName: "settings-clinic" */
                "../views/areas/settings/areas/ClientPortal.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/clinic",
        name: "SettingsClinic",
        component: () =>
            import ( /* webpackChunkName: "settings-clinic" */ "../views/areas/settings/areas/Clinic.vue"),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/roles-permissions",
        name: "SettingsClinic",
        component: () =>
            import (
                /* webpackChunkName: "roles-permissions" */
                "../views/areas/settings/areas/RolesPermissions.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/services",
        name: "SettingsClinicServices",
        component: () =>
            import (
                /* webpackChunkName: "settings-clinic-services" */
                "../views/areas/settings/areas/ClinicServices.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/services/:id",
        name: "SettingsViewClinicService",
        component: () =>
            import (
                /* webpackChunkName: "settings-view-clinic-service" */
                "../views/areas/settings/areas/clinic-services-partials/ServiceView.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/packages/:id",
        name: "SettingsViewClinicPackage",
        component: () =>
            import (
                /* webpackChunkName: "settings-view-clinic-package" */
                "../views/areas/settings/areas/clinic-services-partials/PackageView.vue"
            ),
        meta: {
            auth: true,
        },
    },
    // {
    //   path: '/settings/programmes',
    //   name: 'SettingsOnlineProgrammes',
    //   component: () => import( /* webpackChunkName: "settings-online-programmes" */ '../views/areas/settings/areas/OnlineProgrammes.vue'),
    //   meta: {
    //     auth: true
    //   },
    // },
    // {
    //   path: '/settings/programmes/:id',
    //   name: 'SettingsViewOnlineProgramme',
    //   component: () => import( /* webpackChunkName: "settings-view-online-programme" */ '../views/areas/settings/areas/online-programmes-partials/View.vue'),
    //   meta: {
    //     auth: true
    //   },
    // },
    {
        path: "/settings/terms",
        name: "SettingsClinicTerms",
        component: () =>
            import (
                /* webpackChunkName: "settings-clinic-terms" */
                "../views/areas/settings/areas/ClinicTerms.vue"
            ),
        meta: {
            auth: true,
        },
    },


    {
        path: "/settings/custom-domains/create",
        name: "SettingsCreateCustomDomain",
        component: () =>
            import (
                /* webpackChunkName: "settings-clinic-custom-domains-create" */
                "../views/areas/settings/areas/CreateCustomDomain.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/sender-addresses",
        name: "SettingsSenderAddresses",
        component: () =>
            import (
                /* webpackChunkName: "settings-clinic-sender-addresses" */
                "../views/areas/settings/areas/SenderAddresses.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/tracker",
        name: "SettingsHealthTracker",
        component: () =>
            import ( /* webpackChunkName: "settings-mymop" */ "../views/areas/settings/areas/MyMop.vue"),
        meta: {
            auth: true,
        },
    },

    {
        path: '/settings/products/categories',
        name: 'ProductCategoryIndex',
        component: () => import( /* webpackChunkName: "product-category-index" */ '../views/areas/settings/areas/products/categories/Index'),
        meta: {
        auth: true
        },
    },

    {
        path: '/settings/products/categories/create',
        name: 'ProductCategoryCreate',
        component: () => import( /* webpackChunkName: "custom-product-category-create" */ '../views/areas/settings/areas/products/categories/Create'),
        meta: {
        auth: true
        },
    },

    {
        path: '/settings/products/categories/:id',
        name: 'ProductCategoryView',
        component: () => import( /* webpackChunkName: "custom-product-category-view" */ '../views/areas/settings/areas/products/categories/View'),
        meta: {
        auth: true
        },
    },

    {
        path: '/settings/products',
        name: 'ProductIndex',
        component: () => import( /* webpackChunkName: "custom-product-index" */ '../views/areas/settings/areas/Products'),
        meta: {
        auth: true
        },
    },

    {
        path: '/settings/products/create',
        name: 'ProductCreate',
        component: () => import( /* webpackChunkName: "custom-product-create" */ '../views/areas/settings/areas/products/Create'),
        meta: {
        auth: true
        },
    },

    {
        path: '/settings/products/:id',
        name: 'ProductView',
        component: () => import( /* webpackChunkName: "custom-product-view" */ '../views/areas/settings/areas/products/View'),
        meta: {
        auth: true
        },
    },

    {
        path: "/settings/integrations",
        name: "SettingsIntegrations",
        component: () =>
            import (
                /* webpackChunkName: "settings-integrations" */
                "../views/areas/settings/areas/Integrations.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/timelines",
        name: "SettingsTimelines",
        component: () =>
            import (
                /* webpackChunkName: "settings-timelines" */
                "../views/areas/settings/areas/Timelines.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/phraseology",
        name: "SettingsPhraseology",
        component: () =>
            import (
                /* webpackChunkName: "settings-phraseology" */
                "../views/areas/settings/areas/Phraseology.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/supplements",
        name: "SettingsSupplements",
        component: () =>
            import (
                /* webpackChunkName: "settings-supplements" */
                "../views/areas/settings/areas/Supplements.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/tests",
        name: "SettingsTests",
        component: () =>
            import ( /* webpackChunkName: "settings-tests" */ "../views/areas/settings/areas/Tests.vue"),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/payments",
        name: "SettingsPayments",
        component: () =>
            import (
                /* webpackChunkName: "settings-payments" */
                "../views/areas/payments/SetupStripeConnect.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/user",
        name: "SettingsUser",
        component: () =>
            import ( /* webpackChunkName: "settings-user" */ "../views/areas/settings/areas/User.vue"),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/snippets-appointment-booker",
        name: "SettingsSnippets",
        component: () =>
            import (
                /* webpackChunkName: "settings-snippets-appointment-booker" */
                "../views/areas/settings/areas/snippets/AppointmentBooker.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/snippets-enquiry-form",
        name: "SettingsSnippetsEnquiryForm",
        component: () =>
            import (
                /* webpackChunkName: "settings-snippets-enquiry-form" */
                "../views/areas/settings/areas/snippets/EnquiryForm.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/snippets-health-form",
        name: "SettingsSnippetsHealthForm",
        component: () =>
            import (
                /* webpackChunkName: "settings-snippets-health-form" */
                "../views/areas/settings/areas/snippets/HealthForm.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/exports",
        name: "SettingsExports",
        component: () =>
            import (
                /* webpackChunkName: "settings-exports" */
                "../views/areas/settings/areas/DataExports.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: "/settings/natural-dispensary",
        name: "SettingsNaturalDispensary",
        component: () =>
            import (
                /* webpackChunkName: "settings-natural-dispensary" */
                "../views/areas/settings/areas/NaturalDispensary.vue"
            ),
        meta: {
            auth: true,
        },
    },
    {
        path: '/settings/discount-codes',
        name: 'SettingsDiscountCodes',
        component: () => import( /* webpackChunkName: "settings-discount-codes" */ '../views/areas/settings/areas/DiscountCodes.vue'),
        meta: {
            auth: true
        },
    },
];