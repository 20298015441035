export default [
  {
    path: '/billing',
    name: 'BillingIndex',
    component: () => import( /* webpackChunkName: "billing-index" */ '../views/areas/billing/Index.vue'),
    meta: {
      auth: true
    },
  },
  {
    path: '/billing/subscription',
    name: 'BillingSubscription',
    component: () => import( /* webpackChunkName: "billing-subscription" */ '../views/areas/billing/areas/Subscription'),
    meta: {
      auth: true
    },
  },
  {
    path: '/billing/upgrade',
    name: 'BillingUpgrade',
    component: () => import( /* webpackChunkName: "billing-upgrade" */ '../views/areas/billing/areas/Upgrade'),
    meta: {
      auth: true
    },
  },
  {
    path: '/billing/upgrade/:product',
    name: 'BillingUpgradePayment',
    component: () => import( /* webpackChunkName: "billing-upgrade" */ '../views/areas/billing/areas/UpgradePayment'),
    meta: {
      auth: true
    },
  },
  {
    path: '/billing/seats',
    name: 'BillingSeats',
    component: () => import( /* webpackChunkName: "billing-seats" */ '../views/areas/billing/areas/Seats'),
    meta: {
      auth: true
    },
  },
  {
    path: '/billing/seats/payment',
    name: 'BillingSeatsPayment',
    component: () => import( /* webpackChunkName: "billing-seats-payment" */ '../views/areas/billing/areas/SeatsPayment'),
    meta: {
      auth: true
    },
  },
  {
    path: '/billing/payment-methods',
    name: 'BillingPaymentMethods',
    component: () => import( /* webpackChunkName: "billing-payment-methods" */ '../views/areas/billing/areas/PaymentMethods'),
    meta: {
      auth: true
    },
  },
  {
    path: '/billing/history',
    name: 'BillingHistory',
    component: () => import( /* webpackChunkName: "billing-history" */ '../views/areas/billing/areas/History'),
    meta: {
      auth: true
    },
  },
  {
    path: '/billing/cancel',
    name: 'BillingCancel',
    component: () => import( /* webpackChunkName: "billing-cancel" */ '../views/areas/billing/areas/Cancel'),
    meta: {
      auth: true
    },
  },
  {
    path: '/subscription-expired',
    name: 'SubscriptionExpired',
    component: () => import( /* webpackChunkName: "subscription-expired" */ '../views/areas/billing/SubscriptionExpired'),
    meta: {
      auth: true
    },
  },
];