<template>
  <div>
    <nav
      id="sidebarMenu"
      class="
        onboarding-tour-step-2
        col-md-3 col-lg-2
        d-md-block
        bg-light
        sidebar
        collapse
        overflow-auto
      "
      v-if="client"
    >
      <div class="position-sticky p-3">
        <ul class="nav nav-pills flex-column">
          <li class="nav-item text-sm">
            <router-link
              class="nav-link"
              :class="
                $route.path.includes('dashboard')
                  ? 'router-link-exact-active'
                  : ''
              "
              to="/dashboard"
            >
              <div class="row">
                <div class="col-2 my-auto">
                  <i class="far fa-arrow-left"></i>
                </div>
                <div class="col my-auto">Exit client view</div>
              </div>
            </router-link>
          </li>
        </ul>

        <span
          v-if="client.status === 'contact'"
          class="float-end badge bg-primary my-2"
          >Contact</span
        >
        <h5 class="fw-bold text-primary mt-2 mb-2">
          <span>{{ client.name }}</span>
        </h5>

        <div class="mb-1">
          <button
            class="btn btn-outline-primary btn-xs w-100"
            v-if="client.status !== 'contact' && client.active"
            @click="toggleClientActive"
          >
            <i class="far fa-check me-1"></i>Active
          </button>
          <button
            class="btn btn-outline-danger btn-xs w-100"
            @click="toggleClientActive"
            v-if="!client.active"
            v-tooltip:bottom="
              'Client is inactive. Please provide First Name, Last Name and E-mail Address' +
              (client.is_child ? ' for parent client.' : '.')
            "
          >
            <i class="far fa-times me-1"></i>Inactive
          </button>
        </div>
        <div>
          <button
            v-if="client.status !== 'contact'"
            class="btn btn-success btn-s w-100"
            @click="loginAsClient(client)"
            :disabled="!client.active"
            v-tooltip:bottom="
              !client.active ? 'Only available for active clients.' : ''
            "
          >
            <i class="far fa-eye me-2"></i>View Client Portal
          </button>
          <button
            v-if="client.status == 'contact'"
            class="btn btn-outline-secondary btn-s w-100"
            @click="convertToClient()"
          >
            <i class="far fa-user me-2"></i>Convert to Client
          </button>
        </div>

        <ul
          class="list-group list-group-flush bg-light"
          id="myTab"
          role="tablist"
        >
          <li
            class="
              list-group-item
              p-2
              d-flex
              justify-content-between
              align-items-center
            "
            v-if="client.parent == null"
          >
            <span v-if="!editingEmail">
              <small>{{
                client.email ? client.email : "Email Not Set (Mandatory)"
              }}</small>
            </span>
            <input
              v-if="editingEmail"
              autofocus
              type="text"
              class="form-control me-3"
              @keyup.enter="updateClientInfo"
              v-model="client.email"
            />
            <span class="text-nowrap">
              <i
                class="fas fa-save text-primary cursor-pointer me-1"
                @click="updateClientInfo"
                v-if="editingEmail"
              ></i>
              <i
                class="fas fa-edit ms-auto cursor-pointer"
                @click="editingEmail = !editingEmail"
              ></i>
            </span>
          </li>

          <li
            class="
              list-group-item
              p-2
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <span v-if="!editingDob">
              <small v-if="client.dob != null">{{
                client.dob | formatDate
              }}</small>
              <small v-else>DoB Not Set</small>
            </span>
            <date-input
              v-if="editingDob"
              id="dob"
              :show-formatted="false"
              :clear-button="true"
              v-model="client.dob"
            ></date-input>
            <span class="text-nowrap">
              <i
                class="fas fa-save text-primary cursor-pointer me-1"
                @click="updateClientInfo"
                v-if="editingDob"
              ></i>
              <i
                class="fas fa-edit ms-auto cursor-pointer"
                @click="editingDob = !editingDob"
              ></i>
            </span>
          </li>

          <li
            class="
              list-group-item
              p-2
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <span v-if="!editingGender">
              <small v-if="client.gender != null">{{
                client.gender | capitalize
              }}</small>
              <small v-else>Gender not set</small>
            </span>
            <select
              @change="updateClientInfo"
              class="form-control form-control-sm w-100 me-2"
              v-model="client.gender"
              v-if="editingGender"
            >
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="non-binary">Non-binary</option>
            </select>
            <span class="text-nowrap">
              <i
                class="fas fa-save text-primary cursor-pointer me-1"
                @click="updateClientInfo"
                v-if="editingGender"
              ></i>
              <i
                class="fas fa-edit ms-auto cursor-pointer"
                @click="editingGender = !editingGender"
              ></i>
            </span>
          </li>

          <li
            class="
              list-group-item
              p-2
              d-flex
              justify-content-between
              align-items-center
            "
            v-if="client.parent == null"
          >
            <span v-if="!editingPhone">
              <small>{{
                client.phone ? client.phone : "Primary Number Not Set"
              }}</small>
            </span>
            <phone-number-input
              v-if="editingPhone"
              autofocus
              v-model="client.phone"
              default-country-code="GB"
              :preferred-countries="['GB', 'MT', 'GR', 'ES', 'FR', 'BE', 'DE']"
              :clearable="true"
              @update="updatePhone"
            ></phone-number-input>
            <span class="text-nowrap">
              <i
                class="fas fa-save text-primary cursor-pointer me-1 ms-3"
                @click="updateClientInfo"
                v-if="editingPhone"
              ></i>
              <i
                class="fas fa-edit ms-auto cursor-pointer"
                @click="editingPhone = !editingPhone"
              ></i>
            </span>
          </li>

          <li
            class="
              list-group-item
              p-2
              d-flex
              justify-content-between
              align-items-center
            "
            v-if="client.parent == null"
          >
            <span v-if="!editingMobile">
              <small>{{
                client.mobile ? client.mobile : "Secondary Number Not Set"
              }}</small>
            </span>
            <phone-number-input
              v-if="editingMobile"
              autofocus
              v-model="client.mobile"
              default-country-code="GB"
              :preferred-countries="['GB', 'MT', 'GR', 'ES', 'FR', 'BE', 'DE']"
              :clearable="true"
              @update="updatePhone"
            ></phone-number-input>
            <span class="text-nowrap">
              <i
                class="fas fa-save text-primary cursor-pointer me-1 ms-3"
                @click="updateClientInfo"
                v-if="editingMobile"
              ></i>
              <i
                class="fas fa-edit ms-auto cursor-pointer"
                @click="editingMobile = !editingMobile"
              ></i>
            </span>
          </li>
        </ul>

        <ul class="nav nav-pills flex-column">
          <li class="nav-item" v-for="l in links" :key="l.id">
            <router-link
              class="nav-link"
              :class="
                $route.path.includes('emails') ? 'router-link-exact-active' : ''
              "
              :to="'/clients/' + $route.params.id + '/' + l.id"
            >
              <div class="row">
                <div class="col-2 my-auto">
                  <i class="far" :class="l.icon"></i>
                </div>
                <div class="col text-start my-auto">{{ l.name }}</div>
              </div>
            </router-link>
          </li>
        </ul>
        <!--  -->
        <div class="row my-3" v-if="client.parent == null">
          <div class="col text-center">
            <button
              class="btn btn-outline-primary btn-sm btn-block"
              @click="createChildProfile"
              :disabled="!client.active"
              v-tooltip:bottom="
                client.active
                  ? 'Creating a child profile links the new profile to this one only for communications such as email or text messages. Notes, appointments, consultations & files are all associated with the child\'s profile.'
                  : 'Only available for active clients.'
              "
            >
              <i class="far fa-child me-2"></i>Create Child Profile
            </button>
          </div>
        </div>
        <div class="row" v-if="!this.client.active">
          <div class="col text-center">
            <button
              class="btn btn-outline-danger btn-sm btn-block"
              @click="deleteClient"
            >
              <i class="far fa-trash me-2"></i>Remove Client
            </button>
          </div>
        </div>

        <!--  -->

        <div class="row mt-3 mb-0 text-center">
          <div class="col">
            <ul class="list-group mb-3">
              <li
                class="
                  list-group-item
                  p-2
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <span v-if="!editingBecameClient">
                  <small v-if="client.became_client != null">{{
                    client.became_client | formatDate
                  }}</small>
                  <small v-else>Became Client Not Set</small>
                </span>
                <date-input
                  v-if="editingBecameClient"
                  id="becameClient"
                  :show-formatted="false"
                  :clear-button="true"
                  v-model="client.became_client"
                  @change="updateClientInfo"
                ></date-input>
                <span class="text-nowrap">
                  <i
                    class="fas fa-save text-primary cursor-pointer me-1"
                    @click="updateClientInfo"
                    v-if="editingBecameClient"
                  ></i>
                  <i
                    class="fas fa-edit ms-auto"
                    @click="editingBecameClient = !editingBecameClient"
                  ></i>
                </span>
              </li>
            </ul>
            <p class="mb-0 text-muted">
              <small>
                <i v-if="client.became_client != null"
                  >Client since {{ client.became_client | formatDate }}</i
                >
              </small>
            </p>
          </div>
        </div>

        <!--  -->
        <div
          class="row"
          v-tooltip:auto="
            'Adding tags to clients will enable you to easily sort and filter your clients. Tags are auto-saved so you can re-use them, they are also shared with all practitioners in your clinic.'
          "
        >
          <div class="col mb-5">
            <vue-tags-input
              :placeholder="'Add a client tag'"
              v-model="tag"
              :tags="tags"
              @tags-changed="syncClientTags"
              :autocomplete-items="autocompleteItems"
            />
          </div>
        </div>
      </div>
    </nav>

    <loading
      v-if="!client"
      class="
        col-md-3
        col-lg-2
        mt-4
        d-md-block
        bg-light
        sidebar
        collapse
        overflow-auto
      "
    ></loading>

    <create-child-profile
      :client="client"
      @childProfileCreated="childProfileCreated"
    ></create-child-profile>

  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import CreateChildProfile from "@/views/areas/clients/view-partials/CreateChildProfile";

export default {
  props: ["id"],
  data() {
    return {
      client: null,
      editingEmail: false,
      editingPhone: false,
      editingMobile: false,
      editingDob: false,
      editingGender: false,
      editingBecameClient: false,
      tags: [],
      tag: "",
      autocompleteItems: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    links() {
      return [
        {
          name: "Summary",
          id: "client-summary",
          isActive: true,
          icon: "fa-address-book",
          class: "",
        },
        {
          name: "Details",
          id: "client-details",
          isActive: false,
          icon: "fa-address-card",
          class: "",
        },
        {
          name: "Terms & Agreements",
          id: "terms",
          isActive: false,
          icon: "fa-handshake",
          class: "",
        },
        {
          name: "Allergies & Intolerances",
          id: "allergies",
          isActive: false,
          icon: "fa-allergies",
          class:
            this.client && this.client.has_allergies
              ? "bg-danger-light text-white"
              : "",
        },
        {
          name: "Forms",
          id: "forms",
          isActive: false,
          icon: "fa-align-center",
          class: "",
        },
        {
          name: "Emails",
          id: "emails",
          isActive: false,
          icon: "fa-inbox",
          class: "",
        },
        {
          name: "Consultations",
          id: "consultations",
          isActive: false,
          icon: "fa-user-md",
          class: "",
        },
        {
          name: "Orders",
          id: "orders",
          isActive: false,
          icon: "fa-clipboard-list",
          class: "",
        },
        {
          name: "Timeline",
          id: "timeline",
          isActive: false,
          icon: "fa-list-alt",
          class: "",
        },
        {
          name: "Notes",
          id: "notes",
          isActive: false,
          icon: "fa-notes-medical",
          class: "",
        },
        {
          name: "Files",
          id: "files",
          isActive: false,
          icon: "fa-folders",
          class: "",
        },
        {
          name: "Resources",
          id: "resources",
          isActive: false,
          icon: "fa-paperclip",
          class: "",
        },
        {
          name: "Online Programmes",
          id: "online-programmes",
          isActive: false,
          icon: "fa-signal-stream",
          class: "",
        },
        {
          name: "Health Reports",
          id: "health-reports",
          isActive: false,
          icon: "fa-file-alt",
          class: "",
        },
        {
          name: "Health Tracker",
          id: "health-tracker",
          isActive: false,
          icon: "fa-mind-share",
          class: "bg-silver",
        },
        {
          name: "Health Planner",
          id: "planner",
          isActive: false,
          icon: "fa-hand-holding-seedling",
          class: "bg-silver",
        },
        {
          name: "Health Diary",
          id: "diary",
          isActive: false,
          icon: "fa-book-medical",
          class: "bg-silver",
          counter: this.client ? this.client.unread_health_diary : 0,
        },
        {
          name: "Activity Log",
          id: "activity-log",
          isActive: false,
          icon: "fa-receipt",
          class: "",
        },
      ];
    },
  },
  methods: {
    async fetchClient() {
      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.id
      );
      this.client = client;

      this.tags = [];

      this.client.tags.forEach((t) => {
        this.tags.push({ text: t.name.en, tiClasses: "" });
      });
    },
    toggleClientActive() {
      if (
        !this.client.active ||
        confirm("Are you sure you wish to make this client inactive?")
      ) {
        if (
          this.client.first_name &&
          this.client.last_name &&
          this.client.email
        ) {
          this.client.active = !this.client.active;
          this.updateClientInfo();
        } else {
          alert(
            "The following attributes are required for an active client: First name, Last name, Email address."
          );
        }
      }
    },
    loginAsClient(client) {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            client.id +
            "/login-as-client"
        )
        .then(({ data }) => {
          window.open(data.url, "_blank");
        });
    },
    childProfileCreated(client) {
      //console.log("redirect to ", client);
      const vm = this;
      setTimeout(() => {
        vm.$router.push("/clients/" + client.id);
        location.reload();
      }, 1000);
    },
    createChildProfile() {
      $("#child-profile").modal("show");
    },
    autoComplete() {
      if (this.tag.length > 2) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL + "/autocomplete/tags/available-tags",
            {
              search: this.tag,
            }
          )
          .then(({ data }) => {
            this.autocompleteItems = data.map((t) => {
              return { text: t.name.en };
            });
          });
      }
    },
    displayTags() {
      if (this.client.tags.length > 0) {
        this.client.tags.forEach((t) => {
          this.tags.push({ text: t.name.en, tiClasses: "" });
        });
      } else {
        this.tags = [];
      }
    },
    syncClientTags(newTags) {
      this.tags = newTags;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/" +
            this.client.id +
            "/sync-tags",
          {
            tags: this.tags,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    updatePhone(input) {
      if (input.isValid) {
        this.updateClientInfo();
      }
    },
    deleteClient() {
      var result = confirm(
        "Are you sure you wish to remove this client, doing so will delete orders, notes, events and emails associated with this client!?"
      );
      if (result) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL + "/clients/api/" + this.client.id
          )
          .then(({ data }) => {
            if (data.message === "Client deleted") {
              this.$router.push("/clients");
            } else {
              this.$EventBus.$emit("alert", data);
            }
          });
      }
    },
    convertToClient() {
      this.client.status = "client";
      this.client.active = 1;

      this.updateClientInfo();
    },
    async updateClientInfo() {
      const { data } = await this.$axios.post(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.client.id,
        this.client
      );

      this.editingEmail = false;
      this.editingPhone = false;
      this.editingMobile = false;
      this.editingDob = false;
      this.editingGender = false;
      this.editingBecameClient = false;

      this.$EventBus.$emit("refreshClient");
      this.$EventBus.$emit("alert", data);
    },
  },
  async created() {
    await this.fetchClient();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  components: {
    VueTagsInput,
    CreateChildProfile,
  },
};
</script>

<style>
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
</style>
