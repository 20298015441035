var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.updateExists && !_vm.blockedRoute)?_c('div',{staticClass:"p-2 sticky-top bg-warning"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-auto ml-auto my-auto"},[_c('button',{staticClass:"btn btn-sm btn-light fw-bold",on:{"click":_vm.refreshApp}},[_c('i',{staticClass:"far fa-redo-alt me-2"}),_vm._v("Click to update ")])])])])]):_vm._e(),(_vm.$route.fullPath === '/' || _vm.$route.fullPath === '/login')?_c('front-end-nav',{attrs:{"path":_vm.$route.fullPath}}):_vm._e(),(
      _vm.$route.fullPath != '/events' &&
      _vm.$route.fullPath != '/' &&
      _vm.$route.fullPath != '/login'
    )?_c('main-nav'):_vm._e(),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[(
          _vm.user &&
          _vm.$router.currentRoute.fullPath != '/events' &&
          !_vm.$router.currentRoute.fullPath.includes('/task-boards') &&
          _vm.$router.currentRoute.fullPath != '/task-boards' &&
          !_vm.$router.currentRoute.fullPath.includes('/pipeline') &&
          !_vm.$router.currentRoute.fullPath.includes('/login') &&
          !_vm.$router.currentRoute.fullPath.includes('/settings') &&
          !_vm.$stash.clientInView &&
          !_vm.$router.currentRoute.fullPath.includes('/consultations/video')
        )?_c('side-bar-nav'):_vm._e(),(
          _vm.user &&
          _vm.$router.currentRoute.fullPath.includes('/settings') &&
          !_vm.$router.currentRoute.fullPath.includes('/consultations/video')
        )?_c('settings-side-bar-nav'):_vm._e(),(
          _vm.user &&
          _vm.$router.currentRoute.fullPath.includes('/app-builder') &&
          !_vm.$stash.clientInView &&
          !_vm.$router.currentRoute.fullPath.includes('/consultations/video')
        )?_c('app-builder-side-bar-nav'):_vm._e(),(
          _vm.user &&
          _vm.$stash.clientInView &&
          !_vm.$router.currentRoute.fullPath.includes('/consultations/video') &&
          _vm.$router.currentRoute.fullPath.includes('/clients')
        )?_c('client-profile-side-bar',{key:_vm.$route.fullPath,attrs:{"id":_vm.$stash.clientInView}}):_vm._e(),_c('main',{class:_vm.user &&
          _vm.$router.currentRoute.fullPath != '/events' &&
          !_vm.$router.currentRoute.fullPath.includes('/task-boards') &&
          _vm.$router.currentRoute.fullPath != '/task-boards' &&
          !_vm.$router.currentRoute.fullPath.includes('/pipeline') &&
          !_vm.$router.currentRoute.fullPath.includes('/login') &&
          !_vm.$router.currentRoute.fullPath.includes('/consultations/video')
            ? 'ms-sm-auto col-md-9 col-lg-10'
            : 'col-12'},[(
            (_vm.user && _vm.user.onboarding_status === 'onboarded') ||
            !_vm.user ||
            _vm.$router.currentRoute.fullPath.includes('/login') ||
            _vm.$router.currentRoute.fullPath.includes('/webinars') ||
            _vm.$router.currentRoute.fullPath.includes('/billing')
          )?_c('router-view',{key:_vm.$route.fullPath,staticClass:"pt-4",attrs:{"id":"router-view"}}):(_vm.user && _vm.user.onboarding_status === 'pre_demo')?_c('pre-demo-view'):_vm._e()],1)],1)]),(_vm.user)?_c('snack-bar-alert'):_vm._e(),(_vm.user)?_c('notifications',{attrs:{"user":_vm.user}}):_vm._e(),(_vm.user)?_c('manage-working-hours-modal'):_vm._e(),_c('timezone-check',{attrs:{"user":_vm.user}}),_c('browser-warning'),(_vm.$stash.addClient)?_c('add-client',{attrs:{"user":_vm.user},on:{"closed":function($event){_vm.$stash.addClient = !_vm.$stash.addClient},"added":_vm.clientAdded}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col my-auto"},[_c('p',{staticClass:"text-primary fw-bold mb-0"},[_vm._v(" Important: There is an update available for the Swandoola Practitioner Portal! To receive the latest updates please reload the page or click the button. ")])])}]

export { render, staticRenderFns }