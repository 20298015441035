<template>
  <nav
    id="sidebarMenu"
    class="
      onboarding-tour-step-2
      col-md-3 col-lg-2
      d-md-block
      bg-light
      sidebar
      collapse
      overflow-auto
    "
  >
    <div class="position-sticky pt-4">
      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link class="nav-link" to="/dashboard">
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-arrow-left"></i>
              </div>
              <div class="col my-auto">Exit settings</div>
            </div>
          </router-link>
        </li>
      </ul>

      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-3
          mb-0
          text-muted
        "
      >
        <span>Settings</span>
      </h6>

      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/community')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/community"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-globe"></i>
              </div>
              <div class="col my-auto">Community</div>
            </div>
          </router-link>
        </li>
      </ul>

      <!--  -->

      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-3
          mb-0
          text-muted
        "
        v-if="$can('manage clinic')"
      >
        <span>Clinic</span>
      </h6>

      <ul class="nav nav-pills flex-column">
        <template v-if="$can('manage clinic')">
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="
                $route.path.includes('settings/client-portal')
                  ? 'router-link-exact-active'
                  : ''
              "
              to="/settings/client-portal"
            >
              <div class="row">
                <div class="col-2 my-auto">
                  <i class="far fa-users"></i>
                </div>
                <div class="col my-auto">Client Portal</div>
              </div>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="
                $route.path.includes('settings/clinic')
                  ? 'router-link-exact-active'
                  : ''
              "
              to="/settings/clinic"
            >
              <div class="row">
                <div class="col-2 my-auto">
                  <i class="far fa-clinic-medical"></i>
                </div>
                <div class="col my-auto">Clinic Management</div>
              </div>
            </router-link>

            <div v-if="$route.path.includes('settings/clinic')">
              <a class="btn btn-light w-100 fst-italic" href="#details"
                >Clinic Details</a
              >
              <a class="btn btn-light w-100 fst-italic" href="#logo"
                >Clinic Logo</a
              >
              <a class="btn btn-light w-100 fst-italic" href="#timezone"
                >Clinic Timezone</a
              >
              <a class="btn btn-light w-100 fst-italic" href="#appointments"
                >Clinic Appointment Settings</a
              >
              <a class="btn btn-light w-100 fst-italic" href="#invoices"
                >Clinic Invoice Settings</a
              >
              <a class="btn btn-light w-100 fst-italic" href="#tax-rates"
                >Clinic Tax Rates</a
              >
            </div>
          </li>

          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="
                $route.path.includes('settings/roles-permissions')
                  ? 'router-link-exact-active'
                  : ''
              "
              to="/settings/roles-permissions"
            >
              <div class="row">
                <div class="col-2 my-auto">
                  <i class="far fa-lock"></i>
                </div>
                <div class="col my-auto">Roles &amp; Permissions</div>
              </div>
            </router-link>
          </li>
        </template>

        <li class="nav-item" v-if="$can('manage clinic services')">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/services') ||
              $route.path.includes('settings/packages')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/services"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-box-full"></i>
              </div>
              <div class="col my-auto">Clinic Services</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item" v-if="$can('manage clinic terms')">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/terms')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/terms"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-handshake"></i>
              </div>
              <div class="col my-auto">Clinic Terms</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/tracker')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/tracker"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-mind-share"></i>
              </div>
              <div class="col my-auto text-start">Health Tracker Templates</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/integrations')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/integrations"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-plug"></i>
              </div>
              <div class="col my-auto">Integrations</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/timelines')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/timelines"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-list-alt"></i>
              </div>
              <div class="col my-auto">Timelines</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/phraseology')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/phraseology"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-quote-right"></i>
              </div>
              <div class="col my-auto">Phraseology</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/products')
              || $route.path.includes('settings/products/categories')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/products"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-tag"></i>
              </div>
              <div class="col my-auto">Products</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/supplements')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/supplements"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-pills"></i>
              </div>
              <div class="col my-auto">Supplements</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/tests')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/tests"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-vial"></i>
              </div>
              <div class="col my-auto">Tests</div>
            </div>
          </router-link>
        </li>

        <!-- <li class="nav-item">
          <router-link
            v-if="$can('manage clinic')"
            class="nav-link"
            :class="
              $route.path.includes('settings/natural-dispensary')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/natural-dispensary"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-capsules"></i>
              </div>
              <div class="col my-auto">Natural Dispensary</div>
            </div>
          </router-link>
        </li> -->

        <!-- <li class="nav-item">
          <router-link
            v-if="$can('manage clinic')"
            class="nav-link"
            :class="
              $route.path.includes('settings/discount-codes')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/discount-codes"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-ticket"></i>
              </div>
              <div class="col my-auto">Discount Codes</div>
            </div>
          </router-link>
        </li> -->

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/user')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/user"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-user"></i>
              </div>
              <div class="col my-auto">User</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/exports')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/exports"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-download"></i>
              </div>
              <div class="col my-auto">Data Exports</div>
            </div>
          </router-link>
        </li>
      </ul>
      <!--  -->

      <!--  -->

      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-3
          mb-0
          text-muted
        "
      >
        <span>Website Snippets</span>
      </h6>

      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/snippets-appointment-booker')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/snippets-appointment-booker"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-code"></i>
              </div>
              <div class="col my-auto">Appointment Booker</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/snippets-enquiry-form')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/snippets-enquiry-form"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-code"></i>
              </div>
              <div class="col my-auto">Enquiry Form</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('settings/snippets-health-form')
                ? 'router-link-exact-active'
                : ''
            "
            to="/settings/snippets-health-form"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-code"></i>
              </div>
              <div class="col my-auto">Health Form</div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {};
  },
  beforeCreate() {
    this.$stash.clientInView = null;
  },
};
</script>

<style>
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
</style>
