<template>
  <nav
    id="sidebarMenu"
    class="
      onboarding-tour-step-2
      col-md-3 col-lg-2
      d-md-block
      bg-light
      sidebar
      collapse
      overflow-auto
    "
  >
    <div class="position-sticky pt-4">
      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link class="nav-link" to="/dashboard">
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-arrow-left"></i>
              </div>
              <div class="col my-auto">Exit app builder</div>
            </div>
          </router-link>
        </li>
      </ul>

      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-3
          mb-0
          text-muted
        "
      >
        <span>App Settings</span>
      </h6>

      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('app-builder/general')
                ? 'router-link-exact-active'
                : ''
            "
            to="/app-builder/general"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-rocket"></i>
              </div>
              <div class="col my-auto">General</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('app-builder/navigation')
                ? 'router-link-exact-active'
                : ''
            "
            to="/app-builder/navigation"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-link"></i>
              </div>
              <div class="col my-auto">Navigation</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('app-builder/features')
                ? 'router-link-exact-active'
                : ''
            "
            to="/app-builder/features"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-stars"></i>
              </div>
              <div class="col my-auto">App Features</div>
            </div>
          </router-link>
        </li>
      </ul>
      <!--  -->
      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-3
          mb-0
          text-muted
        "
      >
        <span>User Management</span>
      </h6>

      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('app-builder/features')
                ? 'router-link-exact-active'
                : ''
            "
            to="/app-builder/features"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-stars"></i>
              </div>
              <div class="col my-auto">Users</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('app-builder/reporting')
                ? 'router-link-exact-active'
                : ''
            "
            to="/app-builder/reporting"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-chart-line"></i>
              </div>
              <div class="col my-auto">Reporting</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('app-builder/orders')
                ? 'router-link-exact-active'
                : ''
            "
            to="/app-builder/orders"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-shopping-cart"></i>
              </div>
              <div class="col my-auto">Orders</div>
            </div>
          </router-link>
        </li>
      </ul>

      <!--  -->
      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-3
          mb-0
          text-muted
        "
      >
        <span>Views</span>
      </h6>

      <ul class="nav nav-pills flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('app-builder/look-and-feel')
                ? 'router-link-exact-active'
                : ''
            "
            to="/app-builder/look-and-feel"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-palette"></i>
              </div>
              <div class="col my-auto">Look n' Feel</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('app-builder/pages')
                ? 'router-link-exact-active'
                : ''
            "
            to="/app-builder/pages"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-folder-tree"></i>
              </div>
              <div class="col my-auto">Pages</div>
            </div>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="
              $route.path.includes('app-builder/authentication')
                ? 'router-link-exact-active'
                : ''
            "
            to="/app-builder/authentication"
          >
            <div class="row">
              <div class="col-2 my-auto">
                <i class="far fa-lock"></i>
              </div>
              <div class="col my-auto">Authentication</div>
            </div>
          </router-link>
        </li>
      </ul>

      <!--  -->
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {};
  },
  beforeCreate() {
    this.$stash.clientInView = null;
  },
};
</script>

<style>
/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
</style>
