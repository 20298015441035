import Model from "../Model";
import api from "@/api";
// Model name
const endpoint = "/users";

export default class User extends Model {
  async index() {
    const data = await api.get(endpoint);

    return data;
  }

  async search(term) {
    const data = await api.post(endpoint + "/search", { term: term });

    return data;
  }

  async show(itemId) {
    const data = await api.get(endpoint + "/" + itemId);

    return data;
  }

  async create(payload) {
    const data = await api.post(endpoint, payload);

    return data;
  }

  async update(id, payload) {
    const data = await api.put(endpoint + "/" + id, payload);

    return data;
  }

  async requestLoginToken(email) {
    const data = api.post("/auth/request-token", {
      email: email,
      requester: "practitioners-portal",
    });
    return data;
  }

  async login(token) {
    const data = api.post("/auth/accept-token", {
      token: token,
      requester: "practitioners-portal",
    });
    return data;
  }
}
