<template>
  <div class="d-flex justify-content-center align-items-center text-center">
    <i class="far fa-spinner fa-spin text-primary"></i>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
  .fa-spinner {
    font-size: 1.5rem;
  }
</style>